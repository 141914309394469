import {
  disconnectConference,
  disposeConference,
  joinRoom,
  localAudioTrackUnMute,
  localVideoTrackUnMute,
} from '../../../libs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

import {
  setwaitingRoomModal,
  setRequestDeclined,
  setHostRequiredModal,
  setConferenceConnecting,
  setUserDetail,
  setView,
  setPage,
} from '../../../reduxStore/confSlice';

import { addParticipant, updParticipant } from '../../../utils/socket';
import WaitingRoom from '../../joinFlow/web/waitingRoomModal';
import HostRequired from '../../joinFlow/web/hostRequiredModal';
import { checkBreakoutRoom, isLemMode } from '../../../utils/functions';
import { alertMsg } from '../../common/web/alert';
import { UnableToProcessMsg, viewType } from '../../../utils/util';
let trackRequested = false;
const Connecting = () => {
  const navigate = useNavigate();

  const {
    localUser,
    roomDetail,
    waitingRoomModal,
    hostRequiredModal,
    participantsHost,

  } = useSelector(state => state.conf);
  const dispatch = useDispatch();
  const lemMode = isLemMode();

  useEffect(() => {
    if (
      roomDetail?.host_required &&
      localUser?.role === 'guest' &&
      Object.keys(participantsHost).length === 0
    ) {
      dispatch(setHostRequiredModal(true));
    } else {
      if (localUser?.waiting_status === 'pending') {
        dispatch(setwaitingRoomModal(true));
      }
      if (localUser?.waiting_status === 'accepted') {

        if (waitingRoomModal) {

          if (localUser?.interaction_type === 'streaming' && !lemMode) {
            disconnectConference();
            dispatch(setPage('Lem'));
          }
          checkBreakoutRoom();
          // dispatch(setwaitingRoomModal(false));
        } else {
          dispatch(setConferenceConnecting(false));
        }
        // dispatch(setwaitingRoomModal(false));
        // dispatch(setConferenceConnecting(false));
      }
    }
  }, [localUser, roomDetail, participantsHost]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const tmplocalUser = { ...localUser };
    let currenttimeUTC = new Date().toISOString();
    let timeStampUTC = Date.parse(currenttimeUTC);
    const { active_speaker_default_view } = roomDetail;
    if (active_speaker_default_view) {
      dispatch(setView(viewType.active));
    }

    if (lemMode) {
      if (
        roomDetail?.waiting_room &&
        tmplocalUser?.role === 'guest' &&
        tmplocalUser?.sub_role !== 'presenter'
      ) {
        tmplocalUser.waiting_status = 'pending';
      } else {
        tmplocalUser.waiting_status = 'accepted';
      }
      tmplocalUser.interaction_type = 'streaming';
      tmplocalUser.device_type = 'web';
      tmplocalUser.updated = timeStampUTC;
      dispatch(setUserDetail(tmplocalUser));
      Promise.all([addParticipant(tmplocalUser)]).then(res => { });
    } else {
      joinRoom(tmplocalUser.room)
        .then(async jid => {
          if (
            roomDetail?.waiting_room &&
            tmplocalUser?.role === 'guest' &&
            tmplocalUser?.sub_role !== 'presenter'
          ) {
            tmplocalUser.waiting_status = 'pending';
          } else {
            tmplocalUser.waiting_status = 'accepted';
          }
          tmplocalUser.jid = jid;
          tmplocalUser.interaction_type = 'interactive';
          tmplocalUser.device_type = 'web';

          if (tmplocalUser?.sub_role === 'presenter') {
            tmplocalUser.allow_host_unmute_audio = true;
          }
          if (roomDetail?.everyone_joins_muted) {
            tmplocalUser.audio = false;
          }
          tmplocalUser.allow_host_unmute_audio = true;
          Promise.all([addParticipant(tmplocalUser)]).then(res => {
            dispatch(setUserDetail(tmplocalUser));
          });
        })
        .catch(() => {
          alertMsg({ msg: UnableToProcessMsg }, redirectToHome);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectToHome = () => {
    navigate('/');
  };
  return (
    <>
      {<h1 className="joinLoader">Connecting...</h1>}
      {waitingRoomModal === true && <WaitingRoom />}
      {hostRequiredModal === true && <HostRequired />}
    </>
  );
};
export default Connecting;
