import { createSlice } from '@reduxjs/toolkit';
import { NUMBERS_LIST, supportTokens, viewType } from '../utils/util';
import {
  getParticipiantsJidSortedData,
  getTrackKey,
  isUpdateTrack,
  randomString,
} from '../utils/functions';
export const confSlice = createSlice({
  name: 'conf',
  initialState: {
    deletedRecentUser: {},
    ringTheHostModal: false,
    voipNotification: {},
    nativeUrl: undefined,
    disableCallIntegration: undefined,
    callUUID: null,
    platform: 'web',
    noiseSuppression: true,
    isNoiseSuppressionEnabled: false,
    localAudioTrack: null,
    localVideoTrack: null,
    localDesktopVideoTrack: null,
    localDesktopAudioTrack: null,
    mediaPermissionPrompt: false,
    screenShared: false,
    view: viewType.gallery,
    lastView: viewType.gallery,
    maxVideos: 24,
    dominantSpeaker: null,
    numPages: 1,
    currrentPage: 1,
    screenSharedJid: null,
    prevScreenSharedJid: null,
    conferenceFailed: false,
    conferenceLeft: false,
    ///
    localUser: {},
    roomDetail: {},
    guestDetail: {},
    virtualBackgroundOptions: {},
    ///
    localParticipantData: null,
    remoteParticipantIds: [],
    room: undefined,
    ///
    participantsJID: [],
    participantsList: [],
    participantsUnmuted: [],
    participantsGuest: [],
    participantsHost: [],
    participantsWaiting: [],
    participantsHandRaised: [],
    participantsScreenShared: [],
    mediaShareparticipantsJID: null,
    mediaShareVideoTrack: null,
    ///
    localParticipantDetail: {},
    gallery_participants: '',
    dominant: '',
    selectLanguage: 'en',
    isBackButtonShow: false,
    clickForgotPassword: false,
    chatMessage: [],
    featuredMsg: {},
    currentRoom: '',
    muteAllLoader: false,
    unmuteAllLoader: false,
    myRole: '',
    openLoginModel: false,
    orientation: 'PORTRAIT',
    chatClick: false,
    attendeesClick: false,
    guestsUnmuteLoader: false,
    recordingViewValue: 'active',
    extensionList: [],
    isRequestDeclined: false,
    mutedAttendeesLoader: false,
    waitingStatusLoader: false,
    breakoutRoomJoined: false,
    openRecordingModal: false,
    mic: false,
    camera: false,
    speaker: true,
    raise: false,
    waitingRoomLoaders: {
      accept: [],
      decline: [],
    },
    waitingRoomPopup: false,
    waitingEnabled: false,
    exitModal: false,
    feedbackPopUp: false,
    participantsLoaders: {
      audio: [],
      video: [],
      hand_raised: [],
    },
    failedCameraAlert: false,
    conferenceConnecting: false,
    muteUnmutePermission: false,
    openStreamManageModal: false,
    mouseOverUser: '',
    openUpgradePlanModal: false,
    waitingRoomModal: false,
    hostRequiredModal: false,
    videoPlayModal: false,
    videoPlayLoader: false,
    unreadMessageCount: [],
    showMenu: false,
    showMenuType: '',
    showRecording: false,
    showStreaming: false,
    shareScreen: false,
    openStopRecordingModal: false,
    isRecordingStart: false,
    recordingDuration: 0,
    recordingStopLoader: false,
    recordingStartLoader: false,
    streamingStarted: false,
    startStreamingLoader: false,
    stopStreamingLoader: false,
    addDestinationType: 'custom',
    ratingPopUp: false,
    socketConnected: false,
    recordingData: {},
    recordingName: '',
    streamingTitleDescription: {
      title: '',
      description: '',
      display_social_media_chat: '',
    },
    streamingDestinations: [],
    timeCal: 0,
    intval: null,
    hours: 0,
    minutes: 0,
    seconds: 0,
    streamingData: {},
    streamingDuration: 0,
    destinationsList: [],
    streamingErrors: {},
    destinationListLoader: false,
    maxDestination: false,
    withoutChoosingDestination: false,
    streamTimeCal: 0,
    streamIntval: null,
    streamHours: 0,
    streamMinutes: 0,
    streamSeconds: 0,
    screenDimension: {},
    windowDimension: {},
    page: 'JoinFlow',
    previousRouteStep: '',
    loginAsGuest: false,
    audioInputList: [],
    videoInputList: [],
    audioOutputList: [],
    appAudioDevices: [],
    cameraDevice: null,
    micDevice: null,
    speakerDevice: null,
    tracks: [],
    participants: {},
    hasAudioPermission: false,
    hasVideoPermission: false,
    hasAudioEnable: false,
    hasSpeakerEnable: false,
    hasVideoEnable: false,
    joinWithVideo: false,
    loaderEffect: false,
    waitingTitleInterval: null,
    showInviteModal: false,
    showRoomInfoModal: false,
    showAvSettingModal: false,
    showEditProfileModal: false,
    showPhoneCommandModal: false,
    showWebinarSettingModal: false,
    showRoomSettingModal: false,
    showMeetingModal: false,
    showRoomPermissionModal: false,
    showEventAndRecordingModal: false,
    showShareWidgetModal: false,
    showManageRoomModal: false,
    showInvoiceModal: false,
    userListForRoomPermission: [],
    eventsList: [],
    chatBlockUsers: [],

    trial: false,
    billing: false,

    //extensions
    extensionShow: false,
    extensionDetails: {},
    widgetClick: false,

    kickedOutRequest: false,
    isJoined: false,
    isLemJoined: false,
    last_audio_video_status: {},

    reportProblem: false,
    playerDimensions: { clientWidth: 0, clientHeight: 0 },
    event_promoter: '',
    promoter_id: '',
    localLinkSubsitutation: [],
    joinViaPhone: false,
    micDeviceNotFoundError: false,
    mediaControls: {},
    privateChatUsers: [],
    chatBtnActive: 'group',
    privateChat: '',
    groupChatCount: [],
    hostChatCount: [],
    userExtensionsList: [],
    isOnline: true,
    lemAttendeesCount: 0,
    lemParticipantsModal: false,
    waitingRoomCycle: false,
    roomList: [],
    eventsAndRecordingList: [],
    inviteDetail: {},

    //Event and Recording mobile
    closeModal: '',
    nextModal: '',
    refreshEvenetsAndRecordingList: false,
    appState: 'active',
    appStateSubscription: undefined,
    callIntegrationSubscription: undefined,
    setLemMode: false,

    cardDetail: {},
    kickEveryone: '',
    cookiesGuestDetail: [],

    roomNameEdit: '',
    recentActiveSpeaker: [],
    recentUnmutedUsers: [],

    widgetsFolders: [],
    activeWidget: {},
    widgetItemsList: [],
    mediaUploadPercentage: 0,
    lastActiveWidget: {},
    systemAlertMsg: [],
    lemHlsOption: false,
    selectionModal: false,
    openMicAndCamModal: false,
    joinAdminMode: false,
    forceExit: false,
    openViewFullScreen: false,
    bridgeLogged: false,
    audioLoader: false,
    galleryViewParticipants: [],
    activeParticipant: {},
    presentationParticipant: {},
    activeSmallWindowHide: false,
    lastDragPosition: { x: 0, y: 0 },
    smallWindowSize: { x: 0, y: 0 },
    viewSize: { x: 0, y: 0 },
    trackStreamStatus: {},
    currentActiveVideoTracks: {},
    forceUpdate: {},
    stats: {},
    participantsTracks: {},
    participantIds: [],
    unmutedParticipantIds: [],
    videoContainerSettings: {},
    activeVideoParticipantIds: [],
    audioMode: false,
    videoDisabled: false,
    meetnSocket: null,
    micLoader: false,
    cameraLoader: false,
    remotePacketLoss: {},
    manualSwitchToAudioMode: false,
    numbersList: NUMBERS_LIST,
    UsPhoneNumber: '+1-540-202-2235',
    recordingStateData: {},
    connectionUnstable: false,
    requestedVideosIds: [],
    forwardedSources: [],
    popupLinkModalData: {},
    popupLinkEventData: '',
    refreshLemAttendeesList: '',
    refreshVideoView: '',
    localParticipantKicked: false,
  },

  reducers: {
    SetLocalParticipantKicked: (state, action) => {
      state.localParticipantKicked = action.payload;
    },
    setAudioLevel: () => { },
    alterTalkWhileMuted: () => { },
    updateLocalUserAudio: () => { },
    setConferenceWillJoined: () => { },
    setConferenceJoined: () => { },
    setLemMode: (state, action) => {
      state.lemMode = action.payload;
    },
    setAppStateChanged: (state, action) => {
      state.appState = action.payload;
    },
    setAppStateSubscription: (state, action) => {
      state.appStateSubscription = action.payload;
    },
    setCallIntegrationSubscription: (state, action) => {
      state.callIntegrationSubscription = action.payload;
    },
    setNetworkInfo: (state, action) => {
      assign(state, {
        isOnline: action.payload.isOnline,
        networkType: action.payload.networkType,
        cellularGeneration: action.payload.cellularGeneration,
        details: action.payload.details,
      });
    },
    storeNetworkInfoCleanup: (state, action) => {
      const cleanup = action.payload;
      assign(state, {
        _cleanup: cleanup,
      });
    },
    setDeletedRecentUser: (state, action) => {
      state.deletedRecentUser = action.payload;
    },
    setRingTheHostModal: (state, action) => {
      state.ringTheHostModal = action.payload;
    },
    setVoipNotification: (state, action) => {
      state.voipNotification = action.payload;
    },
    setNativeUrl: (state, action) => {
      state.nativeUrl = action.payload;
    },
    setdisableCallIntegration: (state, action) => {
      state.disableCallIntegration = action.payload;
    },
    setCallUUID: (state, action) => {
      state.callUUID = action.payload;
    },
    setAppAudioDevices: (state, action) => {
      state.appAudioDevices = action.payload;
    },
    setPlatform: (state, action) => {
      state.platform = action.payload;
    },

    setFailedCameraAlert: (state, action) => {
      state.failedCameraAlert = action.payload;
    },
    setConferenceLeft: () => { },
    setConferenceLeftEvent: (state, action) => {
      state.conferenceLeftEvent = action.payload;
    },
    setConferenceFailed: (state, action) => {
      state.conferenceFailed = action.payload;
    },
    setNoiseSuppression: (state, action) => {
      state.noiseSuppression = action.payload;
    },
    setVirtualBackgroundOptions: (state, action) => {
      state.virtualBackgroundOptions = action.payload;
    },
    setNoiseSuppressionEnabledState: (state, action) => {
      state.isNoiseSuppressionEnabled = action.payload;
    },
    setMediaPermissionPrompt: (state, action) => {
      state.mediaPermissionPrompt = action.payload;
    },

    addLocalAudioTrack: (state, action) => {
      const tmpTrack = { ...state.localAudioTrack };
      let track = action.payload.track;
      let device = action.payload.device;
      if (Object.keys(tmpTrack).length > 0) {
        tmpTrack.jitsiTrack.dispose();
      }

      if (track) {
        let trackdata = {
          muted: track?.isMuted(),
          type: track.type,
          jitsiTrack: track,
          device: device,
        };
        state.localAudioTrack = trackdata;
      } else {
        state.localAudioTrack = null;
      }
    },
    updateLocalAudioTrackMute: (state, action) => {
      state.localAudioTrack.muted = action.payload;
    },
    removeLocalAudioTrack: state => {
      state.localAudioTrack = null;
    },

    addLocalVideoTrack: (state, action) => {
      const tmpTrack = { ...state.localVideoTrack };
      let track = action.payload.track;
      let device = action.payload.device;

      if (Object.keys(tmpTrack).length > 0) {
        tmpTrack.jitsiTrack.dispose();
      }
      if (track !== null) {
        let isReceivingData = true;
        let noDataFromSourceNotificationInfo;
        let trackdata = {
          trackID: track.getId(),
          isReceivingData,
          mediaType: track.videoType,
          muted: track.isMuted(),
          noDataFromSourceNotificationInfo,
          participantId: track.getParticipantId(),
          videoStarted: false,
          type: track.type,
          jitsiTrack: track,
          device: device,
        };
        state.camera = track.isMuted();
        state.localVideoTrack = trackdata;
      } else {
        state.camera = false;
        state.localVideoTrack = null;
      }
    },
    updateLocalVideoTrackMute: (state, action) => {
      state.localVideoTrack.muted = action.payload;
    },
    removeLocalVideoTrack: state => {
      state.localVideoTrack = null;
    },
    addLocalDesktopVideoTrack: (state, action) => {
      let track = action.payload;
      let isReceivingData = true;
      let noDataFromSourceNotificationInfo;
      let trackdata = {
        trackID: track.getId(),
        isReceivingData,
        mediaType: track.videoType,
        muted: track.isMuted(),
        noDataFromSourceNotificationInfo,
        participantId: track.getParticipantId(),
        videoStarted: false,
        type: track.type,
        jitsiTrack: track,
      };
      state.localDesktopVideoTrack = trackdata;
    },
    addLocalDesktopAudioTrack: async (state, action) => {
      const tmpTrack = { ...state.localDesktopAudioTrack };
      let track = action.payload;
      if (tmpTrack.length > 0) {
        await tmpTrack.dispose();
      }

      state.localDesktopAudioTrack = track;
    },
    muteLocalDesktopVideoTrack: state => {
      state.localDesktopVideoTrack.muted = true;
    },
    setLocalParticipantData: (state, action) => {
      state.localParticipantData = action.payload;
    },

    setCurrentPage: (state, action) => {
      state.currrentPage = action.payload;
    },
    setNumPages: (state, action) => {
      state.numPages = action.payload;
    },
    setDominantSpeaker: (state, action) => {
      state.dominantSpeaker = action.payload;
    },
    ///
    setSocketConnected: (state, action) => {
      state.socketConnected = action.payload;
    },
    setParticipantsList: (state, action) => {
      let tmpParticipantsList = state.roomDetail.breakout ? { ...state.participantsList } : {};
      let tmpParticipantsJID = {};
      let tmpParticipantsUnmuted = {};
      let tmpParticipantsGuest = {};
      let tmpParticipantsHost = {};
      let tmpParticipantsWaiting = state.roomDetail.breakout ? { ...state.participantsWaiting } : {};
      let tmpParticipantsHandRaised = {};
      let tmpparticipantsScreenShared = {};
      let screenSharedJid = state.screenSharedJid;
      let interactiveParticipants = {};
      let participantIds = [];
      let _unmutedParticipantIds = [];
      let _activeVideoParticipantIds = [];

      let prevInteractiveParticipantsIds = Object.keys(state.participantsJID);
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        action.payload.forEach(val => {
          if (!val?.is_hidden) {
            if (val.token_unique === state.localUser.token_unique) {
              state.localUser = val;
            } else {
              if (
                val.waiting_status === 'accepted' &&
                val.interaction_type === 'interactive' &&
                val.jid
              ) {
                tmpParticipantsJID[val.jid] = val;
                participantIds.push(val.jid);
                if (val.audio === true) {
                  _unmutedParticipantIds.push(val.jid);
                }

                if (val.video === true) {
                  _activeVideoParticipantIds.push(val.jid);
                }
              }
              tmpParticipantsList[val.token_unique] = val;

              if (
                val.role !== 'guest' &&
                val.waiting_status === 'accepted' &&
                val.jid
              ) {
                tmpParticipantsHost[val.token_unique] = val;
              }

              if (
                (val.role === 'guest' || supportTokens.includes(val.token)) &&
                val.waiting_status === 'accepted'
              ) {
                if (val.role === 'guest' && val.jid) {
                  tmpParticipantsGuest[val.token_unique] = val;
                }

                if (tmpParticipantsWaiting[val.token_unique]) {
                  delete tmpParticipantsWaiting[val.token_unique];
                }
              } else if (
                (val.role === 'guest' || supportTokens.includes(val.token)) &&
                val.waiting_status === 'pending'
              ) {
                tmpParticipantsWaiting[val.token_unique] = val;
              }

              if (val.hand_raised) {
                tmpParticipantsHandRaised[val.token_unique] = val;
              } else if (tmpParticipantsHandRaised[val.token_unique]) {
                delete tmpParticipantsHandRaised[val.token_unique];
              }

              if (val.audio && val.waiting_status === 'accepted') {
                tmpParticipantsUnmuted[val.jid] = val;
              } else if (tmpParticipantsUnmuted[val.jid]) {
                delete tmpParticipantsUnmuted[val.jid];
              }

              ///Presentation view code
              if (val.waiting_status === 'accepted' && val?.screenShare && !val?.blocked) {
                tmpparticipantsScreenShared[val.jid] = val.jid;
                screenSharedJid = val.jid;
              }

              ////////
            }
          }
        });
      }
      // active speaker

      if (prevInteractiveParticipantsIds.length > 0) {
        prevInteractiveParticipantsIds.forEach(id => {
          if (tmpParticipantsJID[id]) {
            interactiveParticipants[id] = tmpParticipantsJID[id];
          }
        });
        if (
          Object.keys(tmpParticipantsJID).length >
          prevInteractiveParticipantsIds.length ||
          Object.keys(tmpParticipantsJID).length ===
          prevInteractiveParticipantsIds.length
        ) {
          var uniqueArray = Object.keys(tmpParticipantsJID).filter(function (
            o1,
          ) {
            return !prevInteractiveParticipantsIds.some(function (o2) {
              return o1 === o2;
            });
          });
          uniqueArray.forEach(id => {
            if (tmpParticipantsJID[id]) {
              interactiveParticipants[id] = tmpParticipantsJID[id];
            }
          });
        }
      } else {
        const sortedList = getParticipiantsJidSortedData(
          Object.values(tmpParticipantsJID),
        );
        let UpdatedParticipantsJID = {};

        sortedList.forEach(item => {
          UpdatedParticipantsJID[item.jid] = item;
        });

        interactiveParticipants = { ...UpdatedParticipantsJID };
      }

      state.participantsJID = interactiveParticipants;

      // state.participantsJID = tmpParticipantsJID;
      state.participantsList = tmpParticipantsList;
      state.participantsUnmuted = tmpParticipantsUnmuted;
      state.participantsGuest = tmpParticipantsGuest;
      state.participantsHost = tmpParticipantsHost;
      state.participantsWaiting = tmpParticipantsWaiting;
      state.participantsHandRaised = tmpParticipantsHandRaised;
      state.participantIds = participantIds;
      state.unmutedParticipantIds = _unmutedParticipantIds;
      state.activeVideoParticipantIds = _activeVideoParticipantIds;

      if (!state.mediaShareVideoTrack) {
        state.screenSharedJid = screenSharedJid;
      }
      if (!state.participantsScreenShared[screenSharedJid]) {
        if (screenSharedJid) {
          state.view = viewType.presentation;
        }
      }

      if (
        Object.keys(tmpparticipantsScreenShared).length === 0 &&
        !state.mediaShareparticipantsJID
      ) {
        if (state.view === viewType.presentation) {
          state.view = state.lastView;
        }
      }
      state.participantsScreenShared = tmpparticipantsScreenShared;
      state.forceUpdate = null;
    },
    updParticipantsList: (state, action) => {
      let tmpParticipantsJID = { ...state.participantsJID };
      let tmpParticipantsList = { ...state.participantsList };
      let tmpParticipantsUnmuted = { ...state.participantsUnmuted };
      let tmpParticipantsGuest = { ...state.participantsGuest };
      let tmpParticipantsHost = { ...state.participantsHost };
      let tmpParticipantsWaiting = { ...state.participantsWaiting };
      let tmpparticipantsScreenShared = { ...state.participantsScreenShared };
      let tmpParticipantsHandRaised = { ...state.participantsHandRaised };
      // let tmpRecentUnmutedUser = [...state.recentUnmutedUsers];
      let tmpParticipantIds = [...state.participantIds];
      let _unmutedParticipantIds = [...state.unmutedParticipantIds];
      let _activeVideoParticipantIds = [...state.activeVideoParticipantIds];

      let val = action.payload;
      const participantIndex = tmpParticipantIds.findIndex(
        item => item === val.jid,
      );

      if (!val?.is_hidden) {

        if (val.token_unique === state.localUser.token_unique) {
          state.localUser = val;
        } else {
          const userScreenShared = val?.screenShare && !val?.blocked ? true : false;
          let preScreenSharedValue = tmpParticipantsJID[val.jid]?.screenShare && !tmpParticipantsJID[val.jid]?.blocked ? true : false;
          let previousName = tmpParticipantsJID[val.jid]?.name;
          let previousProfileImage = tmpParticipantsJID[val.jid]?.profile_image;
          let prevOrientation = tmpParticipantsJID[val.jid]?.orientation;

          const existingKey = Object.keys(tmpParticipantsJID).find(key => tmpParticipantsJID[key].token_unique === val.token_unique);

          let prevBlockStatus = tmpParticipantsJID[val.jid]?.blocked;
          let prevKeepMuted = tmpParticipantsJID[val.jid]?.keep_muted;
          if (
            val.waiting_status === 'accepted' &&
            val.interaction_type === 'interactive' &&
            val.jid
          ) {
            if (existingKey && existingKey !== val.jid) {
              delete tmpParticipantsGuest[existingKey];
              tmpParticipantsJID[val.jid] = val;
            } else {
              tmpParticipantsJID[val.jid] = val;
            }

            if (participantIndex === -1) {
              tmpParticipantIds.push(val.jid);
              state.participantIds = tmpParticipantIds;
            }
            if (val.audio === true) {
              if (!_unmutedParticipantIds.includes(val.jid)) {
                _unmutedParticipantIds.push(val.jid);
                state.unmutedParticipantIds = _unmutedParticipantIds;
              }
            } else if (_unmutedParticipantIds.includes(val.jid)) {
              _unmutedParticipantIds = _unmutedParticipantIds.filter(
                item => item !== val.jid,
              );
              state.unmutedParticipantIds = _unmutedParticipantIds;
            }

            if (val.video === true) {
              if (!_activeVideoParticipantIds.includes(val.jid)) {
                _activeVideoParticipantIds.push(val.jid);
                state.activeVideoParticipantIds = _activeVideoParticipantIds;
              }
            } else if (_activeVideoParticipantIds.includes(val.jid)) {
              _activeVideoParticipantIds = _activeVideoParticipantIds.filter(
                item => item !== val.jid,
              );
              state.activeVideoParticipantIds = _activeVideoParticipantIds;
            }

            if (
              val?.name !== previousName ||
              val?.profile_image !== previousProfileImage ||
              val?.orientation !== prevOrientation ||
              val?.blocked !== prevBlockStatus ||
              val?.keep_muted !== prevKeepMuted
            ) {
              state.refreshVideoView = randomString(10);
            }
          }

          if (val.interaction_type === 'streaming') {
            if (tmpParticipantsList[val.token_unique]) {
              delete tmpParticipantsList[val.token_unique];
            }
          } else {
            tmpParticipantsList[val.token_unique] = val;
          }

          if (
            val.role !== 'guest' &&
            val.waiting_status === 'accepted' &&
            val.jid
          ) {
            tmpParticipantsHost[val.token_unique] = val;
            if (tmpParticipantsGuest[val.token_unique]) {
              delete tmpParticipantsGuest[val.token_unique];
            }
          }

          if (
            (val.role === 'guest' || supportTokens.includes(val.token)) &&
            val.waiting_status === 'accepted'
          ) {
            if (val.role === 'guest' && val.jid) {
              tmpParticipantsGuest[val.token_unique] = val;

              if (tmpParticipantsHost[val.token_unique]) {
                delete tmpParticipantsHost[val.token_unique];
              }
            }

            if (tmpParticipantsWaiting[val.token_unique]) {
              delete tmpParticipantsWaiting[val.token_unique];
            }
          } else if (
            (val.role === 'guest' || supportTokens.includes(val.token)) &&
            val.waiting_status === 'declined'
          ) {
            if (tmpParticipantsWaiting[val.token_unique]) {
              delete tmpParticipantsWaiting[val.token_unique];
            }
          } else if (
            (val.role === 'guest' || supportTokens.includes(val.token)) &&
            val.waiting_status === 'pending'
          ) {
            tmpParticipantsWaiting[val.token_unique] = val;
          }

          if (val.hand_raised) {
            tmpParticipantsHandRaised[val.token_unique] = val;
          } else if (tmpParticipantsHandRaised[val.token_unique]) {
            delete tmpParticipantsHandRaised[val.token_unique];
          }

          if (val.audio && val.waiting_status === 'accepted') {
            tmpParticipantsUnmuted[val.jid] = val;
          } else if (tmpParticipantsUnmuted[val.jid]) {
            delete tmpParticipantsUnmuted[val.jid];
          }

          ///Presentation view code

          let screenSharedJid = state.screenSharedJid;
          const prevScreenSharedJid = [];
          if (
            val.waiting_status === 'accepted' &&
            userScreenShared &&
            preScreenSharedValue !== true
          ) {
            tmpparticipantsScreenShared[val.jid] = val.jid;
            screenSharedJid = val.jid;
          } else if (
            val.waiting_status === 'accepted' &&
            !userScreenShared &&
            preScreenSharedValue === true
          ) {
            if (tmpparticipantsScreenShared[val.jid]) {
              delete tmpparticipantsScreenShared[val.jid];
            }

            if (screenSharedJid === val.jid) {
              Object.keys(tmpparticipantsScreenShared).forEach(key =>
                prevScreenSharedJid.push(key),
              );
              if (prevScreenSharedJid.length > 0) {
                screenSharedJid = prevScreenSharedJid[prevScreenSharedJid.length - 1];
              } else {
                screenSharedJid = null;
              }
            }
          }

          if (screenSharedJid && screenSharedJid === val.jid && preScreenSharedValue === false) {
            state.view = viewType.presentation;
          } else if (!screenSharedJid && preScreenSharedValue === true) {
            state.view = state.lastView;
          }
          if (!state.mediaShareVideoTrack) {
            state.screenSharedJid = screenSharedJid;
          }
          state.participantsScreenShared = tmpparticipantsScreenShared;

          ///////////////
        }

        state.participantsJID = tmpParticipantsJID;
        state.participantsList = tmpParticipantsList;
        state.participantsUnmuted = tmpParticipantsUnmuted;
        state.participantsGuest = tmpParticipantsGuest;
        state.participantsHost = tmpParticipantsHost;
        state.participantsWaiting = tmpParticipantsWaiting;
        state.participantsHandRaised = tmpParticipantsHandRaised;

        // state.recentUnmutedUsers = tmpRecentUnmutedUser;
        // state.participantIds = tmpParticipantIds;

        state.forceUpdate = val;
      }
    },
    remParticipantsList: (state, action) => {
      let tmpParticipantsJID = { ...state.participantsJID };
      let tmpParticipantsList = { ...state.participantsList };
      let tmpParticipantsUnmuted = { ...state.participantsUnmuted };
      let tmpParticipantsGuest = { ...state.participantsGuest };
      let tmpParticipantsHost = { ...state.participantsHost };
      let tmpParticipantsWaiting = { ...state.participantsWaiting };
      let tmpparticipantsScreenShared = { ...state.participantsScreenShared };
      let tmpParticipantsHandRaised = { ...state.participantsHandRaised };
      // let tmpRecentUnmutedUser = [...state.recentUnmutedUsers];
      let tmpParticipantIds = [...state.participantIds];
      let _unmutedParticipantIds = [...state.unmutedParticipantIds];
      let _activeVideoParticipantIds = [...state.activeVideoParticipantIds];

      let val = action.payload;

      const updatedParticipantIds = tmpParticipantIds.filter(
        item => item !== val.jid,
      );

      delete tmpParticipantsJID[val.jid];

      delete tmpParticipantsList[val.token_unique];
      delete tmpParticipantsUnmuted[val.jid];
      delete tmpParticipantsGuest[val.token_unique];
      delete tmpParticipantsHost[val.token_unique];

      if (_unmutedParticipantIds.includes(val.jid)) {
        _unmutedParticipantIds = _unmutedParticipantIds.filter(
          item => item !== val.jid,
        );
        state.unmutedParticipantIds = _unmutedParticipantIds;
      }
      if (_activeVideoParticipantIds.includes(val.jid)) {
        _activeVideoParticipantIds = _activeVideoParticipantIds.filter(
          item => item !== val.jid,
        );
        state.activeVideoParticipantIds = _activeVideoParticipantIds;
      }

      if (tmpParticipantsWaiting[val.token_unique]) {
        delete tmpParticipantsWaiting[val.token_unique];
      }

      if (tmpParticipantsHandRaised[val.token_unique]) {
        delete tmpParticipantsHandRaised[val.token_unique];
      }
      ///Presentation view code
      let screenSharedJid = state.screenSharedJid;
      const prevScreenSharedJid = [];
      if (tmpparticipantsScreenShared[val.jid]) {
        delete tmpparticipantsScreenShared[val.jid];
      }

      if (screenSharedJid === val.jid) {
        Object.keys(tmpparticipantsScreenShared).forEach(key =>
          prevScreenSharedJid.push(key),
        );
        if (prevScreenSharedJid.length > 0) {
          screenSharedJid = prevScreenSharedJid[prevScreenSharedJid.length - 1];
        } else {
          screenSharedJid = null;
        }
      }
      if (!state.mediaShareVideoTrack) {
        state.screenSharedJid = screenSharedJid;
      }
      state.participantsScreenShared = tmpparticipantsScreenShared;

      if (!screenSharedJid) {
        state.view = state.lastView;
      }
      ///////////////
      state.participantsJID = tmpParticipantsJID;
      state.participantsList = tmpParticipantsList;
      state.participantsUnmuted = tmpParticipantsUnmuted;
      state.participantsGuest = tmpParticipantsGuest;
      state.participantsHost = tmpParticipantsHost;
      state.participantsWaiting = tmpParticipantsWaiting;
      state.participantsHandRaised = tmpParticipantsHandRaised;
      state.forceUpdate = val;
      state.participantIds = updatedParticipantIds;

      // state.recentUnmutedUsers = tmpRecentUnmutedUser;
    },
    setMediaShareParticipants: (state, action) => {
      const jid = action.payload;
      let trackKey = getTrackKey(jid, 'video');
      let mediaTracks = state.participantsTracks[trackKey];
      state.mediaShareparticipantsJID = jid;

      if (mediaTracks?.muted === false) {
        state.mediaShareVideoTrack = true;
        state.screenSharedJid = jid;
        state.view = viewType.presentation;
      }
      // state.view = viewType.presentation;
    },
    remMediaShareParticipants: (state, action) => {
      const jid = action.payload;
      state.mediaShareparticipantsJID = null;
      state.mediaShareVideoTrack = null;
      let tmpparticipantsScreenShared = { ...state.participantsScreenShared };
      const prevScreenSharedJid = [];
      Object.keys(tmpparticipantsScreenShared).forEach(key =>
        prevScreenSharedJid.push(key),
      );
      if (prevScreenSharedJid.length > 0) {
        state.screenSharedJid =
          prevScreenSharedJid[prevScreenSharedJid.length - 1];
      } else {
        state.screenSharedJid = null;
      }

      if (!state.screenSharedJid) {
        state.view = state.lastView;
      }
    },
    setParticipantsJIDList: (state, action) => {
      const list = action.payload;
      state.participantsJID = list;
    },
    setScreenSharedJid: (state, action) => {
      state.screenSharedJid = action.payload;
    },
    ////////////////////////////////////////////
    setRoomDetails: (state, action) => {
      state.roomDetail = action.payload;
    },
    setUserDetail: (state, action) => {
      state.localUser = action.payload;
    },
    setRoom: (state, action) => {
      state.room = action.payload;
    },
    updateLocalParticipantDetail: (state, action) => {
      state.localParticipantDetail = action.payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
      if (action.payload !== viewType.presentation) {
        state.lastView = state.view;
      }
    },
    dominantViewParticipant: (state, action) => {
      state.dominantSpeaker = action.payload;
    },
    setLanguage: (state, action) => {
      state.selectLanguage = action.payload;
    },
    setBackButtonShow: (state, action) => {
      state.isBackButtonShow = action.payload;
    },
    setOnClickForgotPassword: (state, action) => {
      state.clickForgotPassword = action.payload;
    },
    setChatMessage: (state, action) => {
      state.chatMessage = action.payload;
    },
    setFeatureMsg: (state, action) => {
      state.featuredMsg = action.payload;
    },
    setCurrentRoom: (state, action) => {
      state.currentRoom = action.payload;
    },
    setMuteAllLoader: (state, action) => {
      state.muteAllLoader = action.payload;
    },
    setUnmuteAllLoader: (state, action) => {
      state.unmuteAllLoader = action.payload;
    },
    setMyRole: (state, action) => {
      state.myRole = action.payload;
    },
    setOpenLoginModel: (state, action) => {
      state.openLoginModel = action.payload;
    },
    setOrientation: (state, action) => {
      state.orientation = action.payload;
    },
    setChatClick: (state, action) => {
      state.chatClick = action.payload;
    },
    setAttendeesClick: (state, action) => {
      state.attendeesClick = action.payload;
    },
    setRecordingView: (state, action) => {
      state.recordingViewValue = action.payload;
    },
    setExtensionList: (state, action) => {
      state.extensionList = action.payload;
    },
    setGuestsUnmuteLoader: (state, action) => {
      state.guestsUnmuteLoader = action.payload;
    },
    setRequestDeclined: (state, action) => {
      state.isRequestDeclined = action.payload;
    },
    setMutedAttendeesLoader: (state, action) => {
      state.mutedAttendeesLoader = action.payload;
    },
    setWaitingStatusLoader: (state, action) => {
      state.waitingStatusLoader = action.payload;
    },
    setBreakoutRoomJoined: (state, action) => {
      state.breakoutRoomJoined = action.payload;
    },
    setOpenRecordingModal: (state, action) => {
      state.openRecordingModal = action.payload;
    },
    setMic: (state, action) => {
      state.mic = action.payload;
    },
    setCamera: (state, action) => {
      state.camera = action.payload;
    },
    setSpeaker: (state, action) => {
      state.speaker = action.payload;
    },
    setWaitingRoomLoaders: (state, action) => {
      state.waitingRoomLoaders = action.payload;
    },
    updateWaitingRoomPopup: (state, action) => {
      state.waitingRoomPopup = action.payload;
    },
    setWaitingEnabled: (state, action) => {
      state.waitingEnabled = action.payload;
    },
    setParticipantsLoaders: (state, action) => {
      state.participantsLoaders = action.payload;
    },
    setExitModalShow: (state, action) => {
      state.exitModal = action.payload;
    },
    setFeedbackPopUp: (state, action) => {
      state.feedbackPopUp = action.payload;
    },
    setConferenceConnecting: (state, action) => {
      state.conferenceConnecting = action.payload;
    },
    setMuteUnmutePermission: (state, action) => {
      state.muteUnmutePermission = action.payload;
    },
    setOpenStreamManagerModal: (state, action) => {
      state.openStreamManageModal = action.payload;
    },
    setUpgradePlanModal: (state, action) => {
      state.openUpgradePlanModal = action.payload;
    },
    setwaitingRoomModal: (state, action) => {
      state.waitingRoomModal = action.payload;
    },
    setHostRequiredModal: (state, action) => {
      state.hostRequiredModal = action.payload;
    },
    setMouseOverUser: (state, action) => {
      state.mouseOverUser = action.payload;
    },
    setUnreadMessageCount: (state, action) => {
      state.unreadMessageCount = action.payload;
    },
    setVideoPlayModal: (state, action) => {
      state.videoPlayModal = action.payload;
    },
    setVideoPlayLoader: (state, action) => {
      state.videoPlayLoader = action.payload;
    },
    setShowMenu: (state, action) => {
      state.showMenu = action.payload;
    },
    setShowMenuType: (state, action) => {
      state.showMenuType = action.payload;
    },
    setRecording: (state, action) => {
      state.showRecording = action.payload;
    },
    setStreaming: (state, action) => {
      state.showStreaming = action.payload;
    },

    setShareScreen: (state, action) => {
      state.shareScreen = action.payload;
    },
    setStopRecordingModal: (state, action) => {
      state.openStopRecordingModal = action.payload;
    },
    setIsRecordingStart: (state, action) => {
      state.isRecordingStart = action.payload;
    },
    setRecordingDuration: (state, action) => {
      state.recordingDuration = action.payload;
    },
    setRecordingStopLoader: (state, action) => {
      state.recordingStopLoader = action.payload;
    },
    setRecordingStartLoader: (state, action) => {
      state.recordingStartLoader = action.payload;
    },
    setStreamingStarted: (state, action) => {
      state.streamingStarted = action.payload;
    },
    setStartStreamingLoader: (state, action) => {
      state.startStreamingLoader = action.payload;
    },
    setStopStreamingLoader: (state, action) => {
      state.stopStreamingLoader = action.payload;
    },
    setAddDestinationType: (state, action) => {
      state.addDestinationType = action.payload;
    },
    setRatingPopUp: (state, action) => {
      state.ratingPopUp = action.payload;
    },
    setGuestDetail: (state, action) => {
      state.guestDetail = action.payload;
    },
    setRecordingData: (state, action) => {
      state.recordingData = action.payload;
    },
    setRecordingName: (state, action) => {
      state.recordingName = action.payload;
    },
    setStreamingTitleDescription: (state, action) => {
      state.streamingTitleDescription = action.payload;
    },
    setStreamingDestinations: (state, action) => {
      state.streamingDestinations = action.payload;
    },
    setTimeCal: (state, action) => {
      state.timeCal = action.payload;
    },
    setIntval: (state, action) => {
      state.intval = action.payload;
    },
    setHour: (state, action) => {
      state.hours = action.payload;
    },
    setminutes: (state, action) => {
      state.minutes = action.payload;
    },
    setseconds: (state, action) => {
      state.seconds = action.payload;
    },
    setStreamingData: (state, action) => {
      state.streamingData = action.payload;
    },
    setStreamingDuration: (state, action) => {
      state.streamingDuration = action.payload;
    },
    setMaximumDestination: (state, action) => {
      state.maxDestination = action.payload;
    },
    setDestinationList: (state, action) => {
      state.destinationsList = action.payload;
    },
    setStreamingErrors: (state, action) => {
      state.streamingErrors = action.payload;
    },
    setDestinationListLoader: (state, action) => {
      state.destinationListLoader = action.payload;
    },
    setWithoutChoosingDestination: (state, action) => {
      state.withoutChoosingDestination = action.payload;
    },

    setStreamTimeCal: (state, action) => {
      state.streamTimeCal = action.payload;
    },
    setStreamIntval: (state, action) => {
      state.streamIntval = action.payload;
    },
    setStreamHours: (state, action) => {
      state.streamHours = action.payload;
    },
    setStreamMinutes: (state, action) => {
      state.streamMinutes = action.payload;
    },
    setStreamSeconds: (state, action) => {
      state.streamSeconds = action.payload;
    },
    setJoinWithVideo: (state, action) => {
      state.joinWithVideo = action.payload;
    },
    setScreenDimension: (state, action) => {
      state.screenDimension = action.payload;
    },
    setWindowDimension: (state, action) => {
      state.windowDimension = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPreviousRouteStep: (state, action) => {
      state.previousRouteStep = action.payload;
    },
    setLoginAsGuest: (state, action) => {
      state.loginAsGuest = action.payload;
    },
    sethasAudioEnable: (state, action) => {
      state.hasAudioEnable = action.payload;
    },
    sethasSpeakerEnable: (state, action) => {
      state.hasSpeakerEnable = action.payload;
    },
    sethasVideoEnable: (state, action) => {
      state.hasVideoEnable = action.payload;
    },
    setAudioInputList: (state, action) => {
      state.audioInputList = action.payload;
    },
    setVideoInputList: (state, action) => {
      state.videoInputList = action.payload;
    },
    setAudioOutputList: (state, action) => {
      state.audioOutputList = action.payload;
    },
    setCameraDevice: (state, action) => {
      state.cameraDevice = action.payload;
    },
    setMicDevice: (state, action) => {
      state.micDevice = action.payload;
    },
    setSpeakerDevice: (state, action) => {
      state.speakerDevice = action.payload;
    },
    trackVolumeUpdate: (state, action) => {
      let participant = action.payload;
      let _participantsTracks = { ...state.participantsTracks };
      // let mediaIndex = state.tracks?.findIndex?.(
      //   track =>
      //     track.participantId === participant.participantId &&
      //     track.type === 'audio',
      // );

      // if (mediaIndex !== -1) {
      //   state.tracks[mediaIndex].volume = participant.volume;

      //   if (state.platform === 'native' && state.speaker) {
      //     volumeDouble = Number(participant.volume.toFixed(1));
      //     state.tracks[mediaIndex].jitsiTrack.track._setVolume(volumeDouble);
      //   }
      // }
      let trackKey = getTrackKey(participant.participantId, 'audio');

      if (_participantsTracks[trackKey]) {
        _participantsTracks[trackKey].volume = participant.volume;

        if (state.platform === 'native' && state.speaker) {
          // eslint-disable-next-line no-undef
          volumeDouble = Number(participant.volume.toFixed(1));
          _participantsTracks[trackKey].jitsiTrack.track._setVolume(
            // eslint-disable-next-line no-undef
            volumeDouble,
          );
        }

        state.participantsTracks = _participantsTracks;
      }
    },
    trackAdd: (state, action) => {
      let track = action.payload;

      let isReceivingData = true;
      let noDataFromSourceNotificationInfo;
      let galleryViewParticipants = [...state.galleryViewParticipants];
      let presentationEndpoint =
        state.platform === 'native' &&
          state?.presentationParticipant?.length > 0
          ? state?.presentationParticipant[0]?.jid
          : state?.presentationParticipant?.jid;
      let _participantsTracks = { ...state.participantsTracks };
      if (
        track.type === 'audio' &&
        state.platform === 'native' &&
        state.speaker === false
      ) {
        track.track._setVolume(0);
      }

      let trackdata = {
        trackID: track.getId(),
        isReceivingData,
        mediaType: track.videoType,
        muted: track.isMuted(),
        noDataFromSourceNotificationInfo,
        participantId: track.getParticipantId(),
        videoStarted: false,
        type: track.type,
        jitsiTrack: track,
        volume: 1,
      };

      let trackType =
        track.type === 'video' && track.videoType === 'desktop'
          ? track.videoType
          : track.type;

      let trackKey = getTrackKey(track.getParticipantId(), trackType);

      const isTrackAdd = isUpdateTrack(
        trackType,
        trackKey,
        track,
        _participantsTracks,
      );

      if (isTrackAdd) {
        if (
          track.getParticipantId() === state.mediaShareparticipantsJID &&
          track.type === 'video' &&
          track.videoType === 'camera' &&
          !track.isMuted()
        ) {
          state.mediaShareVideoTrack = true;
          state.screenSharedJid = state.mediaShareparticipantsJID;
          state.view = viewType.presentation;
        }

        // state.tracks.push(trackdata);
        _participantsTracks[trackKey] = trackdata;
        state.participantsTracks = _participantsTracks;

        if (track.type === 'video') {
          if (state.view === viewType.gallery) {
            let checkOnActive = galleryViewParticipants.some(
              u => u.jid === track.getParticipantId(),
            );

            if (checkOnActive) {
              state.currentActiveVideoTracks[trackKey] = trackdata;
            }
          } else if (
            state.view === viewType.active ||
            state.view === viewType.activeExcludingYourself
          ) {
            if (state.activeParticipant?.jid === track.getParticipantId()) {
              state.currentActiveVideoTracks[trackKey] = trackdata;
            }
          } else if (state.view === viewType.presentation) {
            if (presentationEndpoint === track.getParticipantId()) {
              state.currentActiveVideoTracks[trackKey] = trackdata;
            }
          }
        }
      }
    },
    trackRemove: (state, action) => {
      let track = action.payload;
      let _participantsTracks = { ...state.participantsTracks };
      let _currentActiveVideoTracks = { ...state.currentActiveVideoTracks };

      if (
        track.getParticipantId() === state.mediaShareparticipantsJID &&
        track.type === 'video' &&
        track.videoType === 'camera'
      ) {
        state.mediaShareVideoTrack = false;
        let tmpparticipantsScreenShared = { ...state.participantsScreenShared };
        const prevScreenSharedJid = [];
        Object.keys(tmpparticipantsScreenShared).forEach(key =>
          prevScreenSharedJid.push(key),
        );
        if (prevScreenSharedJid.length > 0) {
          state.screenSharedJid =
            prevScreenSharedJid[prevScreenSharedJid.length - 1];
        } else {
          state.screenSharedJid = null;
        }

        if (!state.screenSharedJid) {
          state.view = state.lastView;
        }
      }

      let trackType =
        track.type === 'video' && track.videoType === 'desktop'
          ? track.videoType
          : track.type;
      let trackKey = getTrackKey(track.getParticipantId(), trackType);

      if (trackKey in _participantsTracks) {
        delete _participantsTracks[trackKey];
      }

      state.participantsTracks = _participantsTracks;

      if (trackKey in _currentActiveVideoTracks) {
        delete _currentActiveVideoTracks[trackKey];
        state.currentActiveVideoTracks = _currentActiveVideoTracks;
      }
    },
    partcipantAdd: (state, action) => {
      let participantId = action.payload;
      if (!state.participants[participantId]) {
        state.participants[participantId] = [];
      }
    },
    partcipantRemove: (state, action) => {
      let participantId = action.payload;
      if (state.participants[participantId]) {
        delete state.participants[participantId];
      }
    },
    trackMediaTypeUpdate: (state, action) => {
      let track = action.payload;

      let isReceivingData = true;
      let noDataFromSourceNotificationInfo;
      let _participantsTracks = { ...state.participantsTracks };
      let _currentActiveVideoTracks = { ...state.currentActiveVideoTracks };
      let trackType =
        track.type === 'video' && track.videoType === 'desktop'
          ? track.videoType
          : track.type;

      let trackKey = getTrackKey(track.getParticipantId(), trackType);

      const isTrackUpdate = isUpdateTrack(
        trackType,
        trackKey,
        track,
        _participantsTracks,
      );

      if (isTrackUpdate) {
        if (_participantsTracks[trackKey]) {
          _participantsTracks[trackKey].mediaType = track.videoType;
          state.participantsTracks = _participantsTracks;
          if (trackKey in _currentActiveVideoTracks) {
            _currentActiveVideoTracks[trackKey].mediaType = track.videoType;
            state.currentActiveVideoTracks = _currentActiveVideoTracks;
          }
        } else {
          let galleryViewParticipants = [...state.galleryViewParticipants];
          let presentationEndpoint =
            state.platform === 'native' &&
              state?.presentationParticipant?.length > 0
              ? state?.presentationParticipant[0]?.jid
              : state?.presentationParticipant?.jid;
          let trackdata = {
            trackID: track.getId(),
            isReceivingData,
            mediaType: track.videoType,
            muted: track.isMuted(),
            noDataFromSourceNotificationInfo,
            participantId: track.getParticipantId(),
            videoStarted: false,
            type: track.type,
            jitsiTrack: track,
            volume: 1,
          };
          if (
            track.getParticipantId() === state.mediaShareparticipantsJID &&
            track.type === 'video' &&
            track.videoType === 'camera' &&
            !track.isMuted()
          ) {
            state.mediaShareVideoTrack = true;
            state.screenSharedJid = state.mediaShareparticipantsJID;
            state.view = viewType.presentation;
          }

          // state.tracks.push(trackdata);
          _participantsTracks[trackKey] = trackdata;
          state.participantsTracks = _participantsTracks;

          if (track.type === 'video') {
            if (state.view === viewType.gallery) {
              let checkOnActive = galleryViewParticipants.some(
                u => u.jid === track.getParticipantId(),
              );

              if (checkOnActive) {
                state.currentActiveVideoTracks[trackKey] = trackdata;
              }
            } else if (
              state.view === viewType.active ||
              state.view === viewType.activeExcludingYourself
            ) {
              if (state.activeParticipant?.jid === track.getParticipantId()) {
                state.currentActiveVideoTracks[trackKey] = trackdata;
              }
            } else if (state.view === viewType.presentation) {
              if (presentationEndpoint === track.getParticipantId()) {
                state.currentActiveVideoTracks[trackKey] = trackdata;
              }
            }
          }
        }
      }
      // console.log('_participantsTracks[trackKey]', _participantsTracks, state.participantsTracks, trackKey, track.videoType);
    },
    trackMuteUpdate: (state, action) => {
      let track = action.payload;

      let _participantsTracks = { ...state.participantsTracks };
      let _currentActiveVideoTracks = { ...state.currentActiveVideoTracks };

      let trackType =
        track.type === 'video' && track.videoType === 'desktop'
          ? track.videoType
          : track.type;
      let trackKey = getTrackKey(track.getParticipantId(), trackType);

      const isTrackUpdate = isUpdateTrack(
        trackType,
        trackKey,
        track,
        _participantsTracks,
      );

      if (isTrackUpdate) {
        if (
          track.getParticipantId() === state.mediaShareparticipantsJID &&
          track.type === 'video' &&
          track.isMuted()
        ) {
          state.mediaShareVideoTrack = false;
          let tmpparticipantsScreenShared = { ...state.participantsScreenShared };
          const prevScreenSharedJid = [];
          Object.keys(tmpparticipantsScreenShared).forEach(key =>
            prevScreenSharedJid.push(key),
          );
          if (prevScreenSharedJid.length > 0) {
            state.screenSharedJid =
              prevScreenSharedJid[prevScreenSharedJid.length - 1];
          } else {
            state.screenSharedJid = null;
          }

          if (!state.screenSharedJid) {
            state.view = state.lastView;
          }
        } else if (
          track.getParticipantId() === state.mediaShareparticipantsJID &&
          track.type === 'video' &&
          !track.isMuted()
        ) {
          state.mediaShareVideoTrack = true;
          state.screenSharedJid = state.mediaShareparticipantsJID;
          state.view = viewType.presentation;
        }

        if (_participantsTracks[trackKey]) {
          _participantsTracks[trackKey].muted = track.isMuted();
          state.participantsTracks = _participantsTracks;
        }

        if (trackKey in _currentActiveVideoTracks) {
          _currentActiveVideoTracks[trackKey].muted = track.isMuted();
          state.currentActiveVideoTracks = _currentActiveVideoTracks;
        }
      }
    },
    disposeLocalTracks: (state, action) => {
      const localVideoTrack = state.localVideoTrack;
      const localAudioTrack = state.localAudioTrack;
      const localDesktopVideoTrack = state.localDesktopVideoTrack;

      if (localVideoTrack) {
        localVideoTrack.jitsiTrack.dispose();
        state.localVideoTrack = null;
      }
      if (localDesktopVideoTrack) {
        localDesktopVideoTrack.jitsiTrack.dispose();
        state.localDesktopVideoTrack = null;
      }
      if (localAudioTrack) {
        localAudioTrack.jitsiTrack.dispose();
        state.localAudioTrack = null;
      }

      if (action.payload !== false) {
        state.participantsJID = [];
        state.participantsList = [];
        state.participantsUnmuted = [];
        state.participantsGuest = [];
        state.participantsHost = [];
        state.participantsWaiting = [];
        state.participantsHandRaised = [];
        state.participantsScreenShared = [];
      }
    },
    setHasAudioPermission: (state, action) => {
      state.hasAudioPermission = action.payload;
    },
    setHasVideoPermission: (state, action) => {
      state.hasVideoPermission = action.payload;
    },
    setloaderEffect: (state, action) => {
      state.loaderEffect = action.payload;
    },

    setShowInviteModal: (state, action) => {
      state.showInviteModal = action.payload;
    },
    setShowRoomInfoModal: (state, action) => {
      state.showRoomInfoModal = action.payload;
    },
    setShowAvSettingModal: (state, action) => {
      state.showAvSettingModal = action.payload;
    },
    setShowEditProfileModal: (state, action) => {
      state.showEditProfileModal = action.payload;
    },
    setShowPhoneCommandModal: (state, action) => {
      state.showPhoneCommandModal = action.payload;
    },
    setShowWebinarSettingModal: (state, action) => {
      state.showWebinarSettingModal = action.payload;
    },
    setShowRoomSettingModal: (state, action) => {
      state.showRoomSettingModal = action.payload;
    },
    setShowMeetingModal: (state, action) => {
      state.showMeetingModal = action.payload;
    },
    setShowRoomPermissionModal: (state, action) => {
      state.showRoomPermissionModal = action.payload;
    },
    setEventAndRecordingModal: (state, action) => {
      state.showEventAndRecordingModal = action.payload;
    },
    setShowShareWidgetModal: (state, action) => {
      state.showShareWidgetModal = action.payload;
    },
    setShowManageRoomModal: (state, action) => {
      state.showManageRoomModal = action.payload;
    },
    setShowInvoiceModal: (state, action) => {
      state.showInvoiceModal = action.payload;
    },
    setShowBillingInfoModal: (state, action) => {
      state.showBillingInfoModal = action.payload;
    },
    setUserListForRoomPermission: (state, action) => {
      state.userListForRoomPermission = action.payload;
    },
    setEventsList: (state, action) => {
      state.eventsList = action.payload;
    },
    setChatBlockUser: (state, action) => {
      state.chatBlockUsers = action.payload;
    },

    setMaxVideos: (state, action) => {
      state.maxVideos = action.payload;
    },
    setTrial: (state, action) => {
      state.trial = action.payload;
    },
    setBilling: (state, action) => {
      state.billing = action.payload;
    },

    //extensions
    setExtensionShow: (state, action) => {
      state.extensionShow = action.payload;
    },

    setExtensionDetails: (state, action) => {
      state.extensionDetails = action.payload;
    },
    setWidgetClick: (state, action) => {
      state.widgetClick = action.payload;
    },

    setKickedOutRequest: (state, action) => {
      state.kickedOutRequest = action.payload;
    },
    setIsjoined: (state, action) => {
      state.isJoined = action.payload;
    },
    setIsLemJoined: (state, action) => {
      state.isLemJoined = action.payload;
    },
    audioVideoStatusWhenHostLeave: (state, action) => {
      state.last_audio_video_status = action.payload;
    },
    showReportProblem: (state, action) => {
      state.reportProblem = action.payload;
    },
    setPlayerDimensions: (state, action) => {
      state.playerDimensions = action.payload;
    },
    setEventPromoter: (state, action) => {
      state.event_promoter = action.payload;
    },
    setPromoterId: (state, action) => {
      state.promoter_id = action.payload;
    },
    setLocalLinkSubsitutaion: (state, action) => {
      state.localLinkSubsitutation = action.payload;
    },
    setJoinViaPhone: (state, action) => {
      state.joinViaPhone = action.payload;
    },
    setMicDeviceNotFoundError: (state, action) => {
      state.micDeviceNotFoundError = action.payload;
    },
    setMediaControls: (state, action) => {
      state.mediaControls = action.payload;
    },
    setPrivateChatUsers: (state, action) => {
      state.privateChatUsers = action.payload;
    },
    setChatBtnActive: (state, action) => {
      state.chatBtnActive = action.payload;
    },
    setPrivateChat: (state, action) => {
      state.privateChat = action.payload;
    },
    setGroupChatCount: (state, action) => {
      state.groupChatCount = action.payload;
    },
    setHostChatCount: (state, action) => {
      state.hostChatCount = action.payload;
    },
    setUserExtensionsList: (state, action) => {
      state.userExtensionsList = action.payload;
    },
    setLemAttendeesCount: (state, action) => {
      state.lemAttendeesCount = action.payload;
    },
    setLemParticipantsModal: (state, action) => {
      state.lemParticipantsModal = action.payload;
    },
    setWaitingRoomCycle: (state, action) => {
      state.waitingRoomCycle = action.payload;
    },
    setRoomList: (state, action) => {
      state.roomList = action.payload;
    },
    setEventsAndRecordingList: (state, action) => {
      state.eventsAndRecordingList = action.payload;
    },
    setInviteDetail: (state, action) => {
      state.inviteDetail = action.payload;
    },
    //event and recording mobile
    setHandleCloseModal: (state, action) => {
      state.closeModal = action.payload;
    },
    setHandleNextModal: (state, action) => {
      state.nextModal = action.payload;
    },
    setRefreshEventAndRecordingList: (state, action) => {
      state.refreshEvenetsAndRecordingList = action.payload;
    },
    setAudioModeSubscriptions: (state, action) => {
      _set(state, 'audioModeSubscriptions', action.payload);
    },
    setCardDetail: (state, action) => {
      state.cardDetail = action.payload;
    },
    setKickEveryone: (state, action) => {
      state.kickEveryone = action.payload;
    },
    setCookiesGuestDetail: (state, action) => {
      state.cookiesGuestDetail = action.payload;
    },
    setRoomNameEdit: (state, action) => {
      state.roomNameEdit = action.payload;
    },
    setRecentActiveSpeaker: (state, action) => {
      state.recentActiveSpeaker = action.payload;
    },
    setRecentUnmutedUsers: (state, action) => {
      state.recentUnmutedUsers = action.payload;
    },
    setWidgetsFolders: (state, action) => {
      state.widgetsFolders = action.payload;
    },
    setActiveWidget: (state, action) => {
      state.activeWidget = action.payload;
    },
    setWidgetItemsList: (state, action) => {
      state.widgetItemsList = action.payload;
    },
    setMediaUploadPercentage: (state, action) => {
      state.mediaUploadPercentage = action.payload;
    },
    setLastActiveWidget: (state, action) => {
      state.lastActiveWidget = action.payload;
    },
    showSystemAlert: (state, action) => {
      state.systemAlertMsg = action.payload;
    },
    setLemHlsOption: (state, action) => {
      state.lemHlsOption = action.payload;
    },
    showSelectionModal: (state, action) => {
      state.selectionModal = action.payload;
    },
    setOpemMicAndCamModal: (state, action) => {
      state.openMicAndCamModal = action.payload;
    },
    setAdminMode: (state, action) => {
      state.joinAdminMode = action.payload;
    },
    setForceExit: (state, action) => {
      state.forceExit = action.payload;
    },
    setOpenViewFullScreen: (state, action) => {
      state.openViewFullScreen = action.payload;
    },
    setBridgeLogged: (state, action) => {
      state.bridgeLogged = action.payload;
    },
    setAudioLoader: (state, action) => {
      state.audioLoader = action.payload;
    },
    setGalleryViewParticipants: (state, action) => {
      state.galleryViewParticipants = action.payload;
    },
    setActiveParticipant: (state, action) => {
      state.activeParticipant = action.payload;
    },
    setPresentationParticipant: (state, action) => {
      state.presentationParticipant = action.payload;
    },
    setActiveSmallWindowHide: (state, action) => {
      state.activeSmallWindowHide = action.payload;
    },
    setLastDragPosition: (state, action) => {
      state.lastDragPosition = action.payload;
    },
    setViewSize: (state, action) => {
      state.viewSize = action.payload;
    },
    setTrackStreamStatus: (state, action) => {
      state.trackStreamStatus = { ...state.trackStreamStatus, ...action.payload };
    },
    setCurrentActiveVideoTracks: (state, action) => {
      state.currentActiveVideoTracks = action.payload;
    },
    setForceUpdate: (state, action) => {
      state.forceUpdate = action.payload;
    },
    setStats: (state, action) => {
      state.stats = action.payload;
    },
    setVideoContainerSettings: (state, action) => {
      state.videoContainerSettings = action.payload;
    },
    setAudioMode: (state, action) => {
      state.audioMode = action.payload;
    },
    setVideoDisabled: (state, action) => {
      state.videoDisabled = action.payload;
    },
    setMeetnSocket: (state, action) => {
      state.meetnSocket = action.payload;
    },
    setMicLoaderFlag: (state, action) => {
      state.micLoader = action.payload;
    },
    setCameraLoaderFlag: (state, action) => {
      state.cameraLoader = action.payload;
    },
    setRemotePacketLoss: (state, action) => {
      state.remotePacketLoss = { ...state.remotePacketLoss, ...action.payload };
    },
    setManualSwitchToAudioMode: (state, action) => {
      state.manualSwitchToAudioMode = action.payload;
    },
    setNumbersList: (state, action) => {
      state.numbersList = action.payload;
    },
    setUsPhoneNumber: (state, action) => {
      state.UsPhoneNumber = action.payload;
    },
    setRecordingStateData: (state, action) => {
      state.recordingStateData = action.payload;
    },
    setConnectionUnstable: (state, action) => {
      state.connectionUnstable = action.payload;
    },
    setRequestedVideosIds: (state, action) => {
      state.requestedVideosIds = action.payload;
    },
    setForwardedSources: (state, action) => {
      state.forwardedSources = action.payload;
    },
    setPopupLinkModal: (state, action) => {
      state.popupLinkModalData = action.payload;
    },
    setPopupLinkEventData: (state, action) => {
      state.popupLinkEventData = action.payload;
    },
    setRefreshLemAttendeesList: (state, action) => {
      state.refreshLemAttendeesList = action.payload;
    },
    setCurrentRoomName: (state, action) => {
      state.currentRoomName = action.payload;
    },
    resetParticipantStates: (state, action) => {
      let tmpParticipantsUnmuted = {};
      let tmpParticipantsGuest = {};
      let tmpParticipantsHost = {};
      let tmpParticipantsWaiting = {};
      let tmpParticipantsHandRaised = {};
      let interactiveParticipants = {};
      let participantIds = [];
      let _unmutedParticipantIds = [];
      let _activeVideoParticipantIds = [];

      //attendees states
      state.participantsJID = interactiveParticipants;
      state.participantsUnmuted = tmpParticipantsUnmuted;
      state.participantsGuest = tmpParticipantsGuest;
      state.participantsHost = tmpParticipantsHost;
      // state.participantsWaiting = tmpParticipantsWaiting;
      state.participantsHandRaised = tmpParticipantsHandRaised;
      state.participantIds = participantIds;
      state.unmutedParticipantIds = _unmutedParticipantIds;
      state.activeVideoParticipantIds = _activeVideoParticipantIds;

      if (state.localDesktopVideoTrack) {
        state.localDesktopVideoTrack.jitsiTrack.dispose();
        state.localDesktopVideoTrack = null;
      }

      //other states
      state.attendeesClick = false;
      state.chatClick = false;
      state.chatMessage = [];
      state.featuredMsg = {};
      state.widgetClick = false;
      state.screenSharedJid = '';
      state.extensionShow = false;
      state.extensionDetails = {};
    },
    remBreakoutParticipant: (state, action) => {
      let tmpParticipantsJID = { ...state.participantsJID };
      let tmpParticipantsList = { ...state.participantsList };
      let tmpParticipantsUnmuted = { ...state.participantsUnmuted };
      let tmpParticipantsGuest = { ...state.participantsGuest };
      let tmpParticipantsHost = { ...state.participantsHost };
      let tmpParticipantsWaiting = { ...state.participantsWaiting };
      let tmpparticipantsScreenShared = { ...state.participantsScreenShared };
      let tmpParticipantsHandRaised = { ...state.participantsHandRaised };
      let tmpParticipantIds = [...state.participantIds];
      let _unmutedParticipantIds = [...state.unmutedParticipantIds];
      let _activeVideoParticipantIds = [...state.activeVideoParticipantIds];

      let val = action.payload;

      const updatedParticipantIds = tmpParticipantIds.filter(
        item => item !== val.jid,
      );
      if (val.token_unique === state.localUser.token_unique) {
        state.localUser = val;
      } else {
        tmpParticipantsList[val.token_unique] = val;

        delete tmpParticipantsJID[val.jid];

        delete tmpParticipantsUnmuted[val.jid];
        delete tmpParticipantsGuest[val.token_unique];
        delete tmpParticipantsHost[val.token_unique];

        if (_unmutedParticipantIds.includes(val.jid)) {
          _unmutedParticipantIds = _unmutedParticipantIds.filter(
            item => item !== val.jid,
          );
          state.unmutedParticipantIds = _unmutedParticipantIds;
        }
        if (_activeVideoParticipantIds.includes(val.jid)) {
          _activeVideoParticipantIds = _activeVideoParticipantIds.filter(
            item => item !== val.jid,
          );
          state.activeVideoParticipantIds = _activeVideoParticipantIds;
        }

        // if (tmpParticipantsWaiting[val.token_unique]) {
        //   //  delete tmpParticipantsWaiting[val.token_unique];
        // }

        if (
          (val.role === 'guest' || supportTokens.includes(val.token)) &&
          val.waiting_status === 'accepted'
        ) {
          if (tmpParticipantsWaiting[val.token_unique]) {
            delete tmpParticipantsWaiting[val.token_unique];
          }
        } else if ((val.role === 'guest' || supportTokens.includes(val.token)) && val.waiting_status === 'declined') {
          if (tmpParticipantsWaiting[val.token_unique]) {
            delete tmpParticipantsWaiting[val.token_unique];
          }
        } else if (
          (val.role === 'guest' || supportTokens.includes(val.token)) &&
          val.waiting_status === 'pending'
        ) {
          tmpParticipantsWaiting[val.token_unique] = val;
        }

        if (tmpParticipantsHandRaised[val.token_unique]) {
          delete tmpParticipantsHandRaised[val.token_unique];
        }
        ///Presentation view code
        let screenSharedJid = state.screenSharedJid;
        const prevScreenSharedJid = [];
        if (tmpparticipantsScreenShared[val.jid]) {
          delete tmpparticipantsScreenShared[val.jid];
        }

        if (screenSharedJid === val.jid) {
          Object.keys(tmpparticipantsScreenShared).forEach(key =>
            prevScreenSharedJid.push(key),
          );
          if (prevScreenSharedJid.length > 0) {
            screenSharedJid = prevScreenSharedJid[prevScreenSharedJid.length - 1];
          } else {
            screenSharedJid = null;
          }
        }
        if (!state.mediaShareVideoTrack) {
          state.screenSharedJid = screenSharedJid;
        }
        state.participantsScreenShared = tmpparticipantsScreenShared;

        if (!screenSharedJid) {
          state.view = state.lastView;
        }
      }

      state.participantsJID = tmpParticipantsJID;
      state.participantsList = tmpParticipantsList;
      state.participantsUnmuted = tmpParticipantsUnmuted;
      state.participantsGuest = tmpParticipantsGuest;
      state.participantsHost = tmpParticipantsHost;
      state.participantsWaiting = tmpParticipantsWaiting;
      state.participantsHandRaised = tmpParticipantsHandRaised;
      state.forceUpdate = val;
      state.participantIds = updatedParticipantIds;
    },
  },
});

export const {
  setMicLoaderFlag,
  setCameraLoaderFlag,
  setAudioLevel,
  alterTalkWhileMuted,
  setLemMode,
  setScreenSharedJid,
  updateLocalUserAudio,
  setConferenceLeftEvent,
  setConferenceWillJoined,
  setConferenceJoined,
  setAppStateChanged,
  setAppStateSubscription,
  setAudioModeSubscriptions,
  setCallIntegrationSubscription,
  setAppAudioDevices,
  setNetworkInfo,
  storeNetworkInfoCleanup,
  remMediaShareParticipants,
  setMediaShareParticipants,
  setDeletedRecentUser,
  setRingTheHostModal,
  setVoipNotification,
  setNativeUrl,
  setdisableCallIntegration,
  setCallUUID,
  setPlatform,
  setFailedCameraAlert,
  setConferenceLeft,
  setConferenceFailed,
  setNoiseSuppression,
  setVirtualBackgroundOptions,
  setNoiseSuppressionEnabledState,
  addLocalAudioTrack,
  addLocalVideoTrack,
  addLocalDesktopVideoTrack,
  addLocalDesktopAudioTrack,
  muteLocalDesktopVideoTrack,
  setCurrentPage,
  setNumPages,
  setMediaPermissionPrompt,
  updateLocalAudioTrackMute,
  updateLocalVideoTrackMute,
  removeLocalVideoTrack,
  removeLocalAudioTrack,
  //////////////////////////////

  setloaderEffect,
  setRecentRooms,
  setRoomDetails,
  setUserDetail,
  setRoom,
  setSocketConnected,
  setParticipantsList,
  updParticipantsList,
  remParticipantsList,
  updateLocalParticipantDetail,
  setView,
  dominantViewParticipant,
  setLanguage,
  setBackButtonShow,
  setOnClickForgotPassword,
  setChatMessage,
  setFeatureMsg,
  setCurrentRoom,
  setMuteAllLoader,
  setUnmuteAllLoader,
  setMyRole,
  setOpenLoginModel,
  setOrientation,
  setChatClick,
  setAttendeesClick,
  setExtensionList,
  setGuestsUnmuteLoader,
  setRecordingView,
  setRequestDeclined,
  setMutedAttendeesLoader,
  setWaitingStatusLoader,
  setBreakoutRoomJoined,
  setOpenRecordingModal,
  setMic,
  setCamera,
  setSpeaker,
  setWaitingRoomLoaders,
  updateWaitingRoomPopup,
  setWaitingEnabled,
  setExitModalShow,
  setFeedbackPopUp,
  setParticipantsLoaders,
  setConferenceConnecting,
  setMuteUnmutePermission,
  setOpenStreamManagerModal,
  setMouseOverUser,
  setUpgradePlanModal,
  setwaitingRoomModal,
  setHostRequiredModal,
  setVideoPlayModal,
  setVideoPlayLoader,
  setUnreadMessageCount,
  setShowMenu,
  setShowMenuType,
  setRecording,
  setStreaming,
  setShareScreen,
  setStopRecordingModal,
  setIsRecordingStart,
  setRecordingDuration,
  setRecordingStopLoader,
  setRecordingStartLoader,
  setStreamingStarted,
  setStartStreamingLoader,
  setStopStreamingLoader,
  setAddDestinationType,
  setRatingPopUp,
  setDominantSpeaker,
  setGuestDetail,
  setRecordingData,
  setRecordingName,
  setStreamingTitleDescription,
  setStreamingDestinations,
  setTimeCal,
  setIntval,
  setHour,
  setminutes,
  setseconds,
  setStreamingData,
  setStreamingDuration,
  setDestinationList,
  setStreamingErrors,
  setDestinationListLoader,
  setWithoutChoosingDestination,
  setMaximumDestination,
  setStreamTimeCal,
  setStreamIntval,
  setStreamHours,
  setStreamMinutes,
  setStreamSeconds,
  setScreenDimension,
  setWindowDimension,
  setPage,
  setPreviousRouteStep,
  setLoginAsGuest,
  sethasSpeakerEnable,
  sethasAudioEnable,
  sethasVideoEnable,
  setAudioInputList,
  setVideoInputList,
  setAudioOutputList,
  setCameraDevice,
  setMicDevice,
  setSpeakerDevice,
  trackAdd,
  trackRemove,
  partcipantAdd,
  partcipantRemove,
  trackMediaTypeUpdate,
  trackMuteUpdate,
  disposeLocalTracks,
  setHasAudioPermission,
  setHasVideoPermission,
  setJoinWithVideo,
  setShowInviteModal,
  setShowRoomInfoModal,
  setShowAvSettingModal,
  setShowEditProfileModal,
  setShowPhoneCommandModal,
  setShowWebinarSettingModal,
  setShowRoomSettingModal,
  setShowMeetingModal,
  setShowRoomPermissionModal,
  setEventAndRecordingModal,
  setShowShareWidgetModal,
  setShowManageRoomModal,
  setShowInvoiceModal,
  setShowBillingInfoModal,
  setUserListForRoomPermission,
  setEventsList,
  setChatBlockUser,

  setMaxVideos,
  setBilling,
  setTrial,

  //extensions
  setExtensionShow,
  setExtensionDetails,
  setWidgetClick,

  setKickedOutRequest,
  setIsjoined,
  setIsLemJoined,
  audioVideoStatusWhenHostLeave,
  showReportProblem,
  setPlayerDimensions,
  setPromoterId,
  setEventPromoter,
  setLocalLinkSubsitutaion,
  setJoinViaPhone,
  setMicDeviceNotFoundError,
  setMediaControls,
  trackVolumeUpdate,
  setPrivateChatUsers,
  setChatBtnActive,
  setPrivateChat,
  setGroupChatCount,
  setHostChatCount,
  setUserExtensionsList,
  setLemAttendeesCount,
  setLemParticipantsModal,
  setParticipantsJIDList,
  setWaitingRoomCycle,
  setRoomList,
  setEventsAndRecordingList,
  setInviteDetail,

  //Event and Recording mobile
  setHandleCloseModal,
  setHandleNextModal,
  setRefreshEventAndRecordingList,
  setCardDetail,
  setKickEveryone,
  setCookiesGuestDetail,

  //Edit room name
  setRoomNameEdit,
  setRecentActiveSpeaker,
  setRecentUnmutedUsers,
  setWidgetsFolders,
  setActiveWidget,
  setWidgetItemsList,
  setMediaUploadPercentage,
  setLastActiveWidget,
  showSystemAlert,
  setLemHlsOption,
  showSelectionModal,

  setOpemMicAndCamModal,
  setAdminMode,
  setForceExit,
  setOpenViewFullScreen,
  setBridgeLogged,
  setAudioLoader,
  setGalleryViewParticipants,
  setActiveParticipant,
  setPresentationParticipant,
  setActiveSmallWindowHide,
  setLastDragPosition,
  setViewSize,
  setTrackStreamStatus,
  setCurrentActiveVideoTracks,
  setForceUpdate,
  setStats,
  setVideoContainerSettings,
  setAudioMode,
  setVideoDisabled,
  setMeetnSocket,
  setRemotePacketLoss,
  setManualSwitchToAudioMode,
  setNumbersList,
  setUsPhoneNumber,
  setRecordingStateData,
  setConnectionUnstable,
  setRequestedVideosIds,
  setForwardedSources,
  setPopupLinkModal,
  setPopupLinkEventData,
  setRefreshLemAttendeesList,
  SetLocalParticipantKicked,
  setCurrentRoomName,
  remBreakoutParticipant,
  resetParticipantStates,
} = confSlice.actions;

export default confSlice.reducer;

function assign(target, source) {
  let t = target;

  for (const property in source) {
    // eslint-disable-line guard-for-in
    t = _set(t, property, source[property]);
  }

  return t;
}

function _set(state, property, value) {
  if (
    typeof value === 'undefined' &&
    Object.prototype.hasOwnProperty.call(state, property)
  ) {
    const newState = { ...state };

    if (delete newState[property]) {
      state = newState;
      return state;
    }
  }
  if (state[property] !== value) {
    state[property] = value;
  }

  return state;
}
