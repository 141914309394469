import { configureStore, combineReducers } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import confSlice from './confSlice';
import persistSlice from './persistSlice';
import mainSlice from './mainSlice';
import transcriptionSlice from './transcriptionSlice';
import breakoutSlice from './breakoutSlice';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['persist'],
};

const rootReducer = combineReducers({
  conf: confSlice,
  persist: persistSlice,
  main: mainSlice,
  transcription: transcriptionSlice,
  breakout: breakoutSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export const persistor = persistStore(store);
