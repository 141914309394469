import { AVATAR_COLORS, AVATAR_OPACITY, TILE_ASPECT_RATIO } from './util';
import { setRecentUsers } from '../reduxStore/persistSlice';
import { apiClient } from './api';
import { toast } from 'react-toastify';
import { store } from '../reduxStore/store';

import {
  breakoutItemAdd,
  deleteChatMessage,
  endMeetn,
  getBreakoutManagement,
  getSignedUrl,
  sendChatMessage,
  updParticipant,
  updateInactiveVideoLog,
  updateProfile,
} from './socket';
import axios from 'axios';
import {
  setRoomDetails,
  setMouseOverUser,
  setMuteUnmutePermission,
  setParticipantsLoaders,
  setScreenDimension,
  setUserDetail,
  setVirtualBackgroundOptions,
  setKickedOutRequest,
  setConnectionUnstable,
  updParticipantsList,
  remBreakoutParticipant,
  setParticipantsList,
  setCurrentRoomName,
  resetParticipantStates,
  setwaitingRoomModal,
  setConferenceConnecting,
} from '../reduxStore/confSlice';
import {
  getStats,
  localAudioTrackMute,
  localAudioTrackUnMute,
  localVideoTrackMute,
  localVideoTrackUnMute,
  stopscreenshare,
} from '../libs';
import { resetBreakoutState, setBackToMainRoomLoader, setBreakoutRoomData, setJoinBreakoutRoomLoader } from '../reduxStore/breakoutSlice';

export const Validate = (name, value, signUpPage) => {
  let error = '';
  switch (name) {
    case validationParam.email:
      let emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      let testedEmail = emailRegex.test(value);
      if (!value) {
        error = signUpPage
          ? 'Primary Email is required.'
          : 'Email field is required.';
      } else if (!testedEmail) {
        error = signUpPage
          ? 'Primary email must be a valid email address.'
          : 'Email is incorrect.';
      }
      return error;
    case validationParam.password:
      if (!value) {
        error = 'Password field is required.';
      }
      return error;
    case validationParam.name:
      if (!value) {
        error = signUpPage
          ? 'The name field is required.'
          : 'Name field is required.';
      }
      return error;
    case validationParam.room:
      if (!value) {
        error = 'Please Enter Room Name.';
      }
      return error;
    case validationParam.primaryEmail:
      if (!value) {
        error = 'Primary Email is required';
      }
      return error;
    case validationParam.desiredPassword:
      if (!value) {
        error = 'Desired Password is required';
      }
      return error;
    case validationParam.confirmPassword:
      if (!value) {
        error = 'Confirm Password is required';
      }
      if (value !== signUpPage) {
        error = 'The confirm password must be equal to the password';
      }
      return error;
    case validationParam.phoneNumber:
      if (!value) {
        error = 'Phone number field is required.';
      }
      return error;
    case validationParam.check:
      if (!value) {
        error = 'Term of service must be accepted.';
      }
      return error;
    default:
      return error;
  }
};

export const validationParam = {
  email: 'email',
  password: 'password',
  name: 'name',
  room: 'room',
};

export const recentUsersList = doc => {
  return (dispatch, getState) => {
    const updateDoc = { ...doc };
    const state = getState();
    const { recentUsers } = state.persist;
    let tmpRecentUsers = [...recentUsers];
    console.log(tmpRecentUsers);
    const i = tmpRecentUsers.findIndex(u => u.token === doc.token);
    if (i > -1) {
      if (!doc?.name) {
        updateDoc.name = tmpRecentUsers[i].name;
      }
      if (doc.email === '') {
        if (tmpRecentUsers[i]?.virtual_background) {
          updateDoc.virtual_background = tmpRecentUsers[i].virtual_background;
        }
      }
      tmpRecentUsers[i] = updateDoc;
    } else if (tmpRecentUsers.length < 3) {
      tmpRecentUsers.push(doc);
    } else {
      tmpRecentUsers.shift();
      tmpRecentUsers.push(doc);
    }
    dispatch(setRecentUsers(tmpRecentUsers));
  };
};

export const endMeetnForGuests = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const localUser = state.conf.localUser;
    const roomDetail = state.conf.roomDetail;
    const { room } = roomDetail;
    let res = await endMeetn({ room });
    return res;
  };
};

export const get_browser = () => {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
};

export const _sendChat = (message, cb) => {
  if (message !== '') {
    const trimmed = message.trim();
    if (trimmed) {
      const state = store.getState();
      const { localUser, roomDetail } = state.conf;
      const { token, name } = localUser;
      const { hide_chat_for_guests, room } = roomDetail;
      const payload = {
        room: room,
        token,
        message: message,
        hide_chat_for_guests,
        name: name,
      };
      sendChatMessage(payload);
      cb('');
    }
  }
};

export const randomString = length => {
  var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = '';
  for (var i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export const updateSettings = async req => {
  return await apiClient('room/waiting/setting', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};

export const getInteractiveParticipants = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { participantsList } = state.conf;
    let interactiveAttendees = participantsList.filter(
      u =>
        u.interaction_type !== 'streaming' &&
        !u.is_hidden &&
        u.is_joined &&
        !u.is_recorder,
    );
    return interactiveAttendees;
  };
};

export const setLoader = (type, user) => {
  return (dispatch, getState) => {
    const state = getState();
    const { participantsLoaders } = state.conf;
    const UpdatedParticipantsLoaders = { ...participantsLoaders };
    const particularArr = [...UpdatedParticipantsLoaders[type]];
    particularArr.push(user);
    UpdatedParticipantsLoaders[type] = particularArr;

    dispatch(setParticipantsLoaders(UpdatedParticipantsLoaders));
  };
};

export const getIsUserLocal = (unique_token, localUser) => {
  const { token, token_unique } = localUser;
  let localUniqueToken = `${token}_${token_unique}`;
  let isLocal = false;
  isLocal = unique_token === localUniqueToken ? true : false;
  return isLocal;
};

export const feedback = async req => {
  return await apiClient('room/feedback', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};

export const Capitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getImagesURL = filesData => {
  var filePromises = [];
  for (var i = 0; i < filesData.length; i++) {
    var promise = new Promise(resolve => {
      let file = filesData[i];
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onload = () => {
        resolve(reader.result);
      };
    });
    filePromises.push(promise);
  }
  return Promise.all(filePromises);
};

export const removeTags = str => {
  if (str === null || str === '') {
    return false;
  } else {
    str = str.toString();
  }
  return str.replace(/(<([^>]+)>)/gi, '');
};

export const uploadAttachmentFileWasabi = async ({ _uploadUrl, _file }) => {
  let option = {
    headers: { 'x-amz-acl': 'public-read' },
  };
  try {
    const response = await axios
      .put(_uploadUrl, _file, option)
      .then(_response => {
        return _response;
      })
      .catch(_err => { });
    return response;
  } catch (_err) {
    return _err.response;
  }
};

export const uploadFileInWasabi = selectedFile => {
  let attachments = [];
  const promises = selectedFile.map(async item => {
    const _file = item;
    const file_name = randomString(8);
    const { name: fileExt } = _file;
    let _extension = fileExt.split('.')[1];
    const _response = await getSignedUrl({
      filename: `profile/${file_name + '.' + _extension}`,
    });
    if (_response?.status === 200) {
      let _uploadUrl = _response?.data?.url;
      const _newResponse = await uploadAttachmentFileWasabi({
        _uploadUrl,
        _file,
      });
      if (_newResponse.status === 200) {
        const attachmentName = file_name + '.' + _extension;
        let attachment = {
          attachmentName,
        };
        attachments.push(attachment);
        return attachments;
      }
    }
  });
  return Promise.all(promises);
};

export const secondstoHoursAndMinutes = totalSeconds => {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const timeData = `${hours !== 0 ? `${hours}h` : ''} ${minutes !== 0 ? `${minutes}m` : ''
    } ${seconds !== 0 ? `${seconds}m` : ''}`;
  return timeData;
};

export const getRecordingDuration = () => {
  const state = store.getState();
  const { roomDetail } = state.conf;
  const { recording_timestamp } = roomDetail;
  const timeStampStart = recording_timestamp;
  let currenttimeUTC = new Date().toISOString();
  let timeStampUTC = Date.parse(currenttimeUTC);
  let duration = timeStampStart > 0 ? timeStampUTC - timeStampStart : 0;
  let recordingduration = duration > 0 ? duration : 0;
  return recordingduration;
};

export const getStreamingDuration = () => {
  const state = store.getState();
  const { roomDetail } = state.conf;
  const { streaming_timestamp } = roomDetail;
  const timeStampStart = streaming_timestamp;
  let currenttimeUTC = new Date().toISOString();
  let timeStampUTC = Date.parse(currenttimeUTC);
  let duration = timeStampStart > 0 ? timeStampUTC - timeStampStart : 0;
  let streamingDuration = duration > 0 ? duration : 0;
  return streamingDuration;
};

export const copyChat = () => {
  const state = store.getState();
  const { chatMessage, localUser } = state.conf;
  const { role } = localUser;

  const messageArr = chatMessage.map((v, i) => {
    if (v === undefined) {
      return null;
    }
    const { message, name, hide_chat_for_guests } = v;
    if (hide_chat_for_guests && role === 'guest') {
      return null;
    }
    return `${name}: ${message}`;
  });
  if (messageArr.length > 0) {
    navigator.clipboard
      .writeText(messageArr.join('\n'))
      .then(() => {
        toast.success('Chat history has been copied to your clipboard.', {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch(error => {
        console.error(`Copy failed!`, error);
      });
  } else {
    toast.error('There are no chat messages to copy right now.', {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const btnAccess = type => {
  const state = store.getState();
  const { localUser, roomDetail } = state.conf;
  const { role } = localUser;
  const { hide_chat_for_guests, hide_widgets_for_guests } = roomDetail;

  if (role === 'guest') {
    switch (type) {
      case 'chat':
        return !hide_chat_for_guests;
      case 'widgets':
        return !hide_widgets_for_guests;
    }
  } else {
    return true;
  }
};

export function updateVirtualBackground(options) {
  return async function (dispatch, getState) {
    const state = getState();
    const { localUser } = state.conf;
    const { recentUsers } = state.persist;
    const { name, email } = localUser;
    console.log('email', email);
    if (email) {
      let payload = { virtual_background: options };
      payload.name = name;
      payload.email = email;
      console.log(payload);

      payload.virtual_background;
      updateProfile(payload).then(response => {
        console.log('recentUsers', recentUsers);
        const userIndex = recentUsers.findIndex(
          item => item.token === localUser.token,
        );
        let updatedRecenetUser = [...recentUsers];

        if (userIndex !== -1) {
          let user = { ...updatedRecenetUser[userIndex] };

          if (options.enabled) {
            user['virtual_background'] = options.selectedBackground;
            user['background_type'] = options.backgroundType;
          } else {
            user['virtual_background'] = '';
            user['background_type'] = '';
          }

          updatedRecenetUser[userIndex] = user;

          dispatch(setUserDetail({ ...localUser, ...payload }));
          dispatch(setRecentUsers(updatedRecenetUser));
        }
      });
    } else {
      const userIndex = recentUsers.findIndex(
        item => item.token === localUser.token,
      );
      let updatedRecenetUser = [...recentUsers];

      if (userIndex !== -1) {
        let user = { ...updatedRecenetUser[userIndex] };
        user.virtual_background = options;
        updatedRecenetUser[userIndex] = user;
        dispatch(setRecentUsers(updatedRecenetUser));
      }
    }
    dispatch(setVirtualBackgroundOptions(options));
  };
}

export const updateRecenetUsersList = usersTemp => {
  const state = store.getState();
  const { dispatch } = store;
  const { recentUsers } = state.persist;
  const userIndex = recentUsers.findIndex(
    item => item.token === usersTemp.token,
  );
  let updatedRecenetUser = [...recentUsers];
  if (userIndex !== -1) {
    updatedRecenetUser[userIndex] = usersTemp;
    dispatch(setRecentUsers(updatedRecenetUser));
  }
};

export const validatePhone = event => {
  let phone = event.target.value;
  let result = phone.replace(/[^\d\+]/g, '');
  event.target.value = result;
};
export const playAudio = audioData => {
  var audio = new Audio(audioData.src);
  audio.volume = audioData.volume;
  audio.play();
};

export const getSortedData = data => {
  const participiantData = { ...data };
  const participiantDataWithoutRaisedHand = Object.values(
    participiantData,
  ).filter(item => item.hand_raised_timestamp === undefined);

  const participiantDataWithRaisedHand = Object.values(participiantData).filter(
    item => item.hand_raised_timestamp !== undefined,
  );
  let sortedDataWithRaisedHand = participiantDataWithRaisedHand.sort(function (
    x,
    y,
  ) {
    return y.hand_raised_timestamp - x.hand_raised_timestamp;
  });
  if (participiantDataWithoutRaisedHand.length > 0) {
    sortedDataWithRaisedHand.push(...participiantDataWithoutRaisedHand);
  }
  console.log('sortedDataWithRaisedHand', sortedDataWithRaisedHand);
  return sortedDataWithRaisedHand;
};

export function getAvatarColor(initials) {
  let colorIndex = 0;

  if (initials) {
    let nameHash = 0;

    for (const s of initials) {
      nameHash += s.codePointAt(0);
    }

    colorIndex = nameHash % AVATAR_COLORS.length;
  }

  return `rgba(${AVATAR_COLORS[colorIndex]}, ${AVATAR_OPACITY})`;
}

export function _getAvatarStyle(color) {
  return {
    backgroundColor: color || undefined,
    height: '100 %',
    width: '100 %',
  };
}

export const isLemMode = () => {
  const state = store.getState();
  const { page } = state.conf;

  return page === 'Lem' ? true : false;
};
export const getParticipantsList = () => {
  const { participantsList } = store.getState().conf;

  return Object.keys(participantsList);
};
export const sortParticipantsByName = participants => {
  participants.length > 0 &&
    participants.sort(function (a, b) {
      if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
        return -1;
      }
      if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  return participants;
};

export const haveMicPermission = () => {
  const state = store.getState();
  const {
    localUser,
    roomDetail: { guests_have_extra_powers, guests_can_unmute_themselves },
  } = state.conf;
  let trackAudioStatus = localUser.audio;
  let permission =
    localUser.role === 'guest' &&
      localUser.sub_role !== 'presenter' &&
      !guests_have_extra_powers &&
      !trackAudioStatus &&
      !guests_can_unmute_themselves
      ? false
      : !trackAudioStatus && localUser?.keep_muted
        ? false
        : true;

  return permission;
};

export const handleMic = () => {
  const state = store.getState();
  const dispatch = store.dispatch;
  const { localUser } = state.conf;

  const tmpUser = { ...localUser };
  if (localUser?.audio) {
    localAudioTrackMute().then(res => {
      if (res.audio) {
        tmpUser.audio = false;
        dispatch(setUserDetail(tmpUser));
        updParticipant(tmpUser);
      }
    });
  } else {
    localAudioTrackUnMute().then(res => {
      if (res.audio) {
        tmpUser.audio = true;
        dispatch(setUserDetail(tmpUser));
        updParticipant(tmpUser);
      }
    });
  }
};

export const handleCamera = () => {
  const state = store.getState();
  const dispatch = store.dispatch;
  const { localUser } = state.conf;
  const tmpUser = { ...localUser };
  if (localUser?.video) {
    localVideoTrackMute().then(res => {
      if (res.video) {
        tmpUser.video = false;
        dispatch(setUserDetail(tmpUser));
        updParticipant(tmpUser);
      }
    });
  } else {
    localVideoTrackUnMute().then(res => {
      if (res.video) {
        tmpUser.video = true;
        dispatch(setUserDetail(tmpUser));
        updParticipant(tmpUser);
      }
    });
  }
};

export const formatDate = date => {
  let newDate =
    date.getFullYear() +
    '-' +
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '-' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate());
  return newDate;
};

export const IndividualUserActionPerform = type => {
  const state = store.getState();
  const dispatch = store.dispatch;
  const { localUser } = state.conf;
  console.log('IndividualUserActionPerform', type);
  const tmpUser = { ...localUser };
  switch (type) {
    case 'mute':
    case 'unmute':
      handleMic();
      break;
    case 'stop-screen':
      if (tmpUser?.screenShare) {
        stopscreenshare();
      }
      break;
    case 'stop-video':
      if (localUser?.video) {
        localVideoTrackMute().then(res => {
          if (res.video) {
            tmpUser.video = false;
            dispatch(setUserDetail(tmpUser));
            updParticipant(tmpUser);
          }
        });
      }
      break;
    case 'kicked-out':
      dispatch(setKickedOutRequest(true));
      break;
    case 'ask-to-unmute-true':
    case 'ask-to-unmute-false':
    case 'keep-muted':
    case 'lower-raise-hand':
      console.log('IndividualUserActionPerform type', type);
      if (type === 'ask-to-unmute-true') {
        tmpUser.ask_to_unmute = true;
        dispatch(setMuteUnmutePermission(true));
      } else if (type === 'ask-to-unmute-false') {
        tmpUser.ask_to_unmute = false;
        dispatch(setMuteUnmutePermission(false));
      } else if (type === 'keep-muted') {
        tmpUser.keep_muted = !localUser?.keep_muted;
        if (tmpUser.keep_muted && localUser?.audio) {
          handleMic();
        }
      } else if (type === 'lower-raise-hand') {
        tmpUser.hand_raised = false;
      }
      dispatch(setUserDetail(tmpUser));
      updParticipant(tmpUser);
      break;
    default:
      break;
  }
};

export const featureAccess = (type, plan) => {
  if (type === 'widgets') {
    return plan === 'ultimate' ? true : false;
  } else if (type === 'webinar') {
    return plan === 'ultimate' ? true : false;
  } else {
    return false;
  }
};

export const updateUserSettingsData = (key, cb) => {
  const state = store.getState();
  const { dispatch } = store;
  const { localUser } = state.conf;
  const tmpUser = { ...localUser };
  tmpUser[`${key}`] = !localUser[`${key}`];
  if (localUser?.email !== '') {
    updateProfile(tmpUser).then(res => {
      if (res.status === 200) {
        dispatch(setUserDetail(tmpUser));
        updateRecenetUsersList(tmpUser);
        updParticipant(tmpUser);
      } else {
        cb(res.data[0].msg);
      }
    });
  } else {
    dispatch(setUserDetail(tmpUser));
    updateRecenetUsersList(tmpUser);
    updParticipant(tmpUser);
  }
};

const unMuteAudioHandler = (localUser, type) => {
  const { dispatch } = store;
  const tmpUser = { ...localUser };
  localAudioTrackUnMute().then(res => {
    if (res.audio) {
      tmpUser.audio = true;
      tmpUser.ask_to_unmute = false;
      if (type === 'AU') {
        tmpUser.allow_host_unmute_audio = true;
        updateRecenetUsersList(tmpUser);
      }
      dispatch(setUserDetail(tmpUser));
      updParticipant(tmpUser);
      dispatch(setMuteUnmutePermission(false));
    }
  });
};

export const manageUnmuteRequestPermission = type => {
  const state = store.getState();
  const { dispatch } = store;
  const { localUser } = state.conf;
  const tmpUser = { ...localUser };
  if (type === 'U') {
    unMuteAudioHandler(localUser);
  } else if (type === 'AU') {
    tmpUser.allow_host_unmute_audio = true;
    if (localUser.email !== '') {
      updateProfile(tmpUser).then(res => {
        if (res.status === 200) {
          unMuteAudioHandler(localUser, 'AU');
        }
      });
    } else {
      unMuteAudioHandler(localUser, 'AU');
    }
  } else if (type === 'SM') {
    tmpUser.ask_to_unmute = false;
    dispatch(setUserDetail(tmpUser));
    updParticipant(tmpUser);
    dispatch(setMuteUnmutePermission(false));
  }
};

export const updateLastSpeakerTime = dominantSpeakerId => {
  const state = store.getState();
  const { localUser } = state.conf;
  const { dispatch } = store;
  if (localUser?.jid === dominantSpeakerId) {
    const tmplocalUser = { ...localUser };
    let currenttimeUTC = new Date().toISOString();
    let timeStampUTC = Date.parse(currenttimeUTC);
    tmplocalUser.last_speak_time = timeStampUTC;
    updParticipant(tmplocalUser);
    dispatch(setUserDetail(tmplocalUser));
  }
};

export const getParticipiantsJidSortedData = data => {
  let list = [...data];
  let speakUsers = list.filter(z => z.last_speak_time !== undefined);
  const unSpeakUser = list.filter(b => b.last_speak_time === undefined);
  if (speakUsers.length > 0) {
    const sortedSpeakUsers = speakUsers.sort(function (x, y) {
      return y.last_speak_time - x.last_speak_time;
    });
    list = [...sortedSpeakUsers, ...unSpeakUser];
  }
  return list;
};

export const getTrackKey = (id, type) => {
  return `${id}_${type}`;
};

export const calculateGallerySize = participants => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      screenDimension,
      chatClick,
      attendeesClick,
      widgetClick,
      windowDimension,
    } = state.conf;
    const { liveCaption } = state.transcription;
    let browser = get_browser();

    let { clientWidth, clientHeight } = windowDimension;

    let { columns, minVisibleRows, maxColumns = 6, rows } = screenDimension;
    let height = 0,
      width = 0,
      viewWidth = 0,
      navBarHeight = 0,
      sideBarWidth = 0,
      screenWidth = 0,
      btmBarHeight = 0,
      screenHeight = 0;
    // navBarHeight = 59.2

    let body_zoomLevel =
      window.getComputedStyle(document.querySelector('body')).zoom || 1;

    const columnsToMaintainASquare = Math.ceil(Math.sqrt(participants));
    columns = Math.min(columnsToMaintainASquare, maxColumns);
    rows = Math.ceil(participants / columns);
    minVisibleRows = Math.min(maxColumns, rows);

    navBarHeight = document.getElementById('conference-nav-bar')?.clientHeight;
    if (liveCaption) {
      btmBarHeight = document.getElementById('caption-bar')?.clientHeight;
    }

    const sideMargins = 30 * 2;
    //const sideMargins = 0

    if (chatClick || attendeesClick || widgetClick) {
      sideBarWidth = document.getElementById('conferecne-sidebar')?.clientWidth;
      if (browser?.name === 'Safari') {
        viewWidth = clientWidth / body_zoomLevel - sideMargins - sideBarWidth;
        screenWidth = clientWidth / body_zoomLevel - sideBarWidth;
      } else {
        viewWidth = clientWidth - sideMargins - sideBarWidth;
        screenWidth = clientWidth - sideBarWidth;
      }
    } else {
      if (browser?.name === 'Safari') {
        viewWidth = clientWidth / body_zoomLevel - sideMargins;
        screenWidth = clientWidth / body_zoomLevel;
      } else {
        viewWidth = clientWidth - sideMargins;
        screenWidth = clientWidth;
      }
    }

    if (browser?.name === 'Safari') {
      screenHeight =
        clientHeight / body_zoomLevel - navBarHeight - btmBarHeight;
    } else {
      screenHeight = clientHeight - navBarHeight - btmBarHeight;
    }

    const initialWidth = viewWidth / columns;
    const aspectRatioHeight = initialWidth / TILE_ASPECT_RATIO;

    const initialHeight = screenHeight / minVisibleRows;

    height = Math.floor(Math.min(aspectRatioHeight, initialHeight));
    width = Math.floor(TILE_ASPECT_RATIO * height);

    dispatch(
      setScreenDimension({
        ...screenDimension,
        ...{
          columns,
          rows,
          minVisibleRows,
          height,
          width,
          viewWidth,
          screenHeight,
          screenWidth,
        },
      }),
    );
  };
};

export const isUpdateTrack = (
  trackType,
  trackKey,
  track,
  participantsTracks,
) => {
  let check = false;
  if (trackType === 'video') {
    if (participantsTracks[trackKey]) {
      const sourceName = participantsTracks[trackKey]?.jitsiTrack?._sourceName;
      if (sourceName === track?._sourceName) {
        check = true;
      } else {
        check = false;
      }
    } else {
      check = true;
    }
  } else {
    check = true;
  }

  return check;
};

export const checkParticipantsVideoStreamStatus = () => {
  const state = store.getState();
  const {
    roomDetail,
    galleryViewParticipants,
    localUser,
    trackStreamStatus,
    requestedVideosIds,
    forwardedSources,
  } = state.conf;
  const remoteGalleryparticipants = galleryViewParticipants.filter(
    user => user.jid !== localUser.jid,
  );
  let stats = getStats();
  let bandwidth = stats.bandwidth;
  let bitrate = stats.bitrate;
  const inactiveEndpoints = [];
  remoteGalleryparticipants.forEach(user => {
    let status = trackStreamStatus[user?.jid];
    if (status === 'restoring' || status === 'interrupted') {
      inactiveEndpoints.push({
        jid: user.jid,
        name: user.name,
        video_status: status,
      });
    }
  });
  // console.log('inactiveEndpoints', inactiveEndpoints, requestedVideosIds, forwardedSources);
  //send Log
  if (inactiveEndpoints.length > 0) {
    let payload = {
      room: roomDetail?.room,
      token_unique: localUser?.token_unique,
      name: localUser?.name,
      inactiveEndpoints,
      bandwidth,
      requestedEndpoints: requestedVideosIds,
      forwardedEndpoints: forwardedSources,
      bitrate,
    };
    //console.log('updateInactiveVideoLog', payload);
    updateInactiveVideoLog(payload);
  }
};

export const validateUnstableConnection = () => {
  const dispatch = store.dispatch;
  const state = store.getState();
  const {
    galleryViewParticipants,
    localUser,
    trackStreamStatus,
    connectionUnstable,
    roomDetail,
  } = state.conf;

  const alertToReloadDuringHalfVideoInterrupted =
    roomDetail?.alertToReloadDuringHalfVideoInterrupted;

  if (alertToReloadDuringHalfVideoInterrupted) {
    const remoteGalleryparticipants = galleryViewParticipants.filter(
      user => user.jid !== localUser.jid,
    );
    const galleryViewParticipantsCount = galleryViewParticipants.length;
    let inactiveLimit = Math.floor((galleryViewParticipantsCount * 50) / 100);
    const inactiveEndpoints = [];
    remoteGalleryparticipants.forEach(user => {
      let status = trackStreamStatus[user?.jid];
      if (status === 'restoring' || status === 'interrupted') {
        inactiveEndpoints.push(user.jid);
      }
    });
    let inactiveLimitExceed =
      inactiveEndpoints.length > inactiveLimit &&
        galleryViewParticipantsCount > 8
        ? true
        : false;
    //let inactiveLimitExceed = galleryViewParticipantsCount > 8 ? true : false;
    //console.log('UnstableConnection', inactiveEndpoints, inactiveLimit, inactiveLimitExceed);
    //show alert
    if (inactiveLimitExceed) {
      dispatch(setConnectionUnstable(true));
    } else if (connectionUnstable) {
      dispatch(setConnectionUnstable(false));
    }
  }
};

export const getParticipantAudioStatus = participant => {
  const state = store.getState();
  const { participantsJID } = state.conf;
  let currentStatus = participantsJID[participant?.jid]?.audio;
  return currentStatus;
};

export const getTrackVideoStatus = videoTrack => {
  const state = store.getState();
  const { trackStreamStatus } = state.conf;
  let currentStatus = trackStreamStatus[videoTrack?.ownerEndpointId];

  return currentStatus;
};

export const isTrackStreamingStatusActive = videoTrack => {
  const state = store.getState();
  const { trackStreamStatus } = state.conf;
  let currentStatus = trackStreamStatus[videoTrack?.ownerEndpointId];

  let isActive =
    currentStatus === 'inactive' ||
      currentStatus === 'restoring' ||
      currentStatus === 'interrupted'
      ? false
      : true;
  // let isActive = currentStatus === 'inactive' || currentStatus === 'interrupted' ? false : true;

  return isActive;
};

export const getPartcipiantsUserIds = () => {
  const state = store.getState();
  const { view, galleryViewParticipants, participantsTracks } = state.conf;
  const usersId = [];

  // if (view !== 'gallery') {
  galleryViewParticipants.forEach(item => {
    const videoTrackkey = getTrackKey(item.jid, 'video');
    const userVideoTrack = participantsTracks[videoTrackkey];

    if (userVideoTrack?.muted === false) {
      usersId.push(userVideoTrack.jitsiTrack.getSourceName());
    }
  });
  // }

  return usersId;
};

export const handleBreakoutRoom = (join, doc) => {
  const dispatch = store.dispatch;
  const state = store.getState();
  const { breakoutRoomData } = state.breakout;
  const { roomDetail, currentRoomName, localUser, } = state.conf;
  console.log('breakoutRoomData', breakoutRoomData, roomDetail?.room, currentRoomName);

  if (join && doc?.breakout_room !== currentRoomName) {
    console.log('join to breakout room', doc);
    dispatch(setBreakoutRoomData({ breakout_room: doc.id, return_to_mainroom: doc.return_to_mainroom, breakout_room_name: doc?.bk }));

    dispatch(resetParticipantStates());
    const tmpUser = { ...localUser };

    const prevJid = localUser.jid;
    tmpUser.prevJid = prevJid;
    tmpUser.jid = '';
    if (tmpUser?.screenShare) {
      stopscreenshare();
      tmpUser.screenShare = false;
    }
    tmpUser.breakout = true;
    tmpUser.breakout_status = 'pending';
    tmpUser.breakout_id = doc.id;
    console.log('tmpUser', tmpUser);
    updParticipant(tmpUser).then((response) => {
      console.log('response', response);
      // dispatch(setCurrentRoomName(doc?.id));
      // dispatch(setJoinBreakoutRoomLoader(true));
    });

    dispatch(setCurrentRoomName(doc?.id));
    dispatch(setJoinBreakoutRoomLoader(true));

  } else if (join === false && currentRoomName !== roomDetail?.room) {
    dispatch(setBreakoutRoomData({}));
    dispatch(resetParticipantStates());
    dispatch(resetBreakoutState());
    const tmpUser = { ...localUser };
    const prevJid = localUser.jid;
    tmpUser.prevJid = prevJid;
    tmpUser.jid = '';
    if (tmpUser?.screenShare) {
      stopscreenshare();
      tmpUser.screenShare = false;
    }
    tmpUser.breakout = false;
    tmpUser.breakout_status = '';
    tmpUser.breakout_id = '';
    console.log('tmpUser', tmpUser);
    updParticipant(tmpUser).then((response) => {
      console.log('response', response);
      // dispatch(setCurrentRoomName(roomDetail?.room));
      // dispatch(setBackToMainRoomLoader(true));
    });
    dispatch(setCurrentRoomName(roomDetail?.room));
    dispatch(setBackToMainRoomLoader(true));

  }
};
export const handleBreakoutParticipantsList = (doc = [], joining = false) => {
  const dispatch = store.dispatch;
  const state = store.getState();
  const { roomDetail, localUser, currentRoomName, participantsList } = state.conf;
  const { breakoutRoomJoined } = state.breakout;

  const listArray = Object.values(participantsList);
  console.log('participantsList listArray', listArray,);
  if (breakoutRoomJoined || joining) {
    let filteredUsers = listArray.filter(v => v?.breakout_id === currentRoomName && v?.breakout_status === 'completed');
    console.log('Breakout=> filteredUsers', filteredUsers);
    dispatch(setParticipantsList(filteredUsers));

  } else {
    let filteredUsers = listArray.filter(v => !v?.breakout_id);
    console.log('Breakout=> filteredUsers2', filteredUsers);
    dispatch(setParticipantsList(filteredUsers));
  }
};

export const handleBreakoutIndividualAttendee = (doc) => {
  const dispatch = store.dispatch;
  const state = store.getState();
  const { roomDetail, localUser, currentRoomName } = state.conf;
  const { breakoutRoomJoined } = state.breakout;

  if (breakoutRoomJoined) {
    if (doc?.breakout_id === currentRoomName && doc?.breakout_status === 'completed') {
      dispatch(updParticipantsList(doc));
    } else {
      console.log('Breakout=> filteredUsers breakout else', doc);
      if (!doc.jid && doc.prevJid) {
        doc.jid = doc.prevJid;
      }
      dispatch(remBreakoutParticipant(doc));
    }
  } else {
    if (!doc?.breakout_id) {
      console.log('Breakout=> filteredUsers if', doc?.breakout_id);
      dispatch(updParticipantsList(doc));
    } else {
      console.log('Breakout=> filteredUsers else', doc?.breakout_id);
      if (!doc.jid && doc.prevJid) {
        doc.jid = doc.prevJid;
      }
      dispatch(remBreakoutParticipant(doc));
    }

  }
};
export const checkBreakoutRoom = () => {
  const dispatch = store.dispatch;
  const state = store.getState();
  const { roomDetail, localUser } = state.conf;

  console.log('roomDetail', roomDetail?.breakout, roomDetail?.new_attendee_join_room);

  if (roomDetail?.breakout) {

    if (roomDetail?.new_attendee_join_room === 'breakout') {

      const payload = {
        room: roomDetail?.room,
        token_unique: localUser?.token_unique,
      };

      getBreakoutManagement(payload).then((response) => {
        console.log('Breakout=> getBreakoutManagement==>', response.data);

        if (response.status === 200) {
          const list = response.data;
          //dispatch(setConferenceConnecting(false));

          const attendeeCounts = list.map(item => item.attendees.length);

          const minAttendees = Math.min(...attendeeCounts);
          const maxAttendees = Math.max(...attendeeCounts);

          let roomData;
          if (minAttendees === maxAttendees) {
            roomData = [list[0]];
          } else {
            roomData = list.filter(item => item.attendees.length === minAttendees);
          }
          console.log('roomData', roomData);
          if (roomData?.length > 0) {
            const roomId = roomData[0]?.id;
            const attendees = roomData[0]?.attendees[0];

            const data = {
              id: roomId,
              bk: attendees?.bk,
              return_to_mainroom: attendees?.return_to_mainroom,
            };
            console.log('roomId', roomId, data);

            let addPayload = {
              id: roomId,
              name: localUser?.name,
              main_room: roomDetail?.room,
              token_unique: localUser?.token_unique,
              role: localUser?.role,
              bk: attendees?.bk,
            };
            breakoutItemAdd(addPayload).then(() => {
              console.log('breakoutItemAdd',);
              dispatch(setwaitingRoomModal(false));
              dispatch(setConferenceConnecting(false));
              handleBreakoutRoom(true, data);
            });

          } else {
            dispatch(setConferenceConnecting(false));
            dispatch(setwaitingRoomModal(false));
          }
        } else {
          dispatch(setConferenceConnecting(false));
          dispatch(setwaitingRoomModal(false));
        }
      });
    } else {
      handleBreakoutParticipantsList();
      dispatch(setConferenceConnecting(false));
      dispatch(setwaitingRoomModal(false));
    }

  } else {
    dispatch(setConferenceConnecting(false));
    dispatch(setwaitingRoomModal(false));
  }
};

export const checkAudioTrackStatus = (track) => {
  const state = store.getState();
  const { dispatch } = store;
  const { localUser, roomDetail } = state.conf;
  let tmplocalUser = { ...localUser };
  // check audio mute status

  console.log('checkAudioTrackStatus', localUser?.audio, track?.isMuted());
  if (roomDetail?.everyone_joins_muted && !track?.isMuted()) {
    console.log('checkAudioTrackStatus 2',);



    track?.mute().then((res) => {
      console.log('checkAudioTrackStatus then', res);
      tmplocalUser.audio = false;
      updParticipant(tmplocalUser);
      dispatch(setUserDetail(tmplocalUser));
    }).catch((err) => {
      console.log('checkAudioTrackStatus catch', err);
      tmplocalUser.audio = true;
      updParticipant(tmplocalUser);
      dispatch(setUserDetail(tmplocalUser));
    });


  } else {
    const audioTrackStatus = track?.isMuted() === false ? true : false;
    console.log('checkAudioTrackStatus audioTrackStatus', tmplocalUser?.audio, audioTrackStatus);
    if (tmplocalUser?.audio !== audioTrackStatus) {
      console.log('checkAudioTrackStatus audioTrackStatus update', audioTrackStatus);
      tmplocalUser.audio = audioTrackStatus;
      updParticipant(tmplocalUser);
      dispatch(setUserDetail(tmplocalUser));
    }
  }
};