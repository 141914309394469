import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    breakoutClick: false,
    openManual: false,
    openAutomatic: false,
    openManagement: false,
    includeHosts: false,
    messageOpenScreen: false,
    endBreakoutOpen: false,
    people: [],
    rooms: [],
    breakoutRoomData: {},
    joinBreakoutRoom: false,
    attendeeStatusScreen: false,
    hostarray: [],
    breakoutRoomJoined: false,
    backToMainRoomLoader: false,
};
export const breakoutSlice = createSlice({
    name: 'breakout',
    initialState,
    reducers: {
        resetBreakoutState: () => initialState,
        setBreakoutClick: (state, action) => {
            state.breakoutClick = action.payload;
        },
        setOpenManual: (state, action) => {
            state.openManual = action.payload;
        },
        setOpenAutomatic: (state, action) => {
            state.openAutomatic = action.payload;
        },
        setOpenManagement: (state, action) => {
            state.openManagement = action.payload;
        },
        setIncludeHosts: (state, action) => {
            state.includeHosts = action.payload;
        },
        setMessageOpenScreen: (state, action) => {
            state.messageOpenScreen = action.payload;
        },
        setEndBreakoutOpen: (state, action) => {
            state.endBreakoutOpen = action.payload;
        },
        setPeople: (state, action) => {
            state.people = action.payload;
        },
        setRooms: (state, action) => {
            state.rooms = action.payload;
        },
        setBreakoutRoomData: (state, action) => {
            state.breakoutRoomData = action.payload;
        },
        setJoinBreakoutRoomLoader: (state, action) => {
            state.joinBreakoutRoom = action.payload;
        },
        setAttendeeStatusScreen: (state, action) => {
            state.attendeeStatusScreen = action.payload;
        },
        setHostArray: (state, action) => {
            state.hostarray = action.payload;
        },
        setBreakoutRoomJoined: (state, action) => {
            state.breakoutRoomJoined = action.payload;
        },
        setBackToMainRoomLoader: (state, action) => {
            state.backToMainRoomLoader = action.payload;
        },
    },
});

export const {
    resetBreakoutState,
    setBreakoutClick,
    setOpenManual,
    setOpenAutomatic,
    setOpenManagement,
    setPeople,
    setRooms,
    setIncludeHosts,
    setMessageOpenScreen,
    setEndBreakoutOpen,
    setBreakoutRoomData,
    setJoinBreakoutRoomLoader,
    setAttendeeStatusScreen,
    setHostArray,
    setBreakoutRoomJoined,
    setBackToMainRoomLoader,
} = breakoutSlice.actions;

export default breakoutSlice.reducer;
