import { disconnectConference, joinRoom, } from '../../../libs';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAttendeesList, updParticipant } from '../../../utils/socket';
import { setBackToMainRoomLoader, setBreakoutRoomJoined, setJoinBreakoutRoomLoader } from '../../../reduxStore/breakoutSlice';
import { handleBreakoutParticipantsList } from '../../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { UnableToProcessMsg } from '../../../utils/util';
import swal from 'sweetalert';

const BreakoutConnecting = ({ joining }) => {

    const localUser = useSelector(state => state.conf.localUser);
    const roomDetail = useSelector(state => state.conf.roomDetail);
    const breakoutRoomData = useSelector(state => state.breakout.breakoutRoomData);
    const backToMainRoomLoader = useSelector(state => state.breakout.backToMainRoomLoader);
    const localtmpUser = useRef(localUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        localtmpUser.current = localUser;
    }, [localUser]);
    useEffect(() => {
        let room = joining ? breakoutRoomData?.breakout_room : roomDetail?.room;
        console.log('room', room);
        disconnectConference(false, 'switch_room').then(() => {
            joinRoom(room)
                .then(async jid => {
                    const tmpUser = { ...localtmpUser.current };
                    tmpUser.jid = jid;
                    if (joining) {
                        dispatch(setJoinBreakoutRoomLoader(false));
                        dispatch(setBreakoutRoomJoined(true));
                        tmpUser.breakout_id = room;
                        tmpUser.breakout_status = 'completed';
                        updParticipant(tmpUser);
                    } else {
                        dispatch(setBackToMainRoomLoader(false));
                        dispatch(setBreakoutRoomJoined(false));
                        tmpUser.breakout_id = '';
                        tmpUser.breakout_status = '';
                        tmpUser.breakout = false;
                        updParticipant(tmpUser);
                    }

                    getAllAttendeesList({ room: roomDetail?.room, token: localUser?.token_unique }).then((response) => {
                        if (response.status === 200) {
                            if (response.data) {
                                handleBreakoutParticipantsList(response.data, joining);
                            }
                        }
                    });
                })
                .catch(error => {
                    console.log('connecting', error);
                    swal({
                        title: UnableToProcessMsg,
                        closeOnClickOutside: false,
                        buttons: ['Exit', 'Retry'],
                        dangerMode: true,
                    }).then(async retry => {
                        if (retry) {
                            window.location.reload();
                        } else {
                            redirectToHome();
                        }
                    });
                });
        }).catch(() => {
            swal({
                title: UnableToProcessMsg,
                closeOnClickOutside: false,
                buttons: ['Exit', 'Retry'],
                dangerMode: true,
            }).then(async retry => {
                if (retry) {
                    window.location.reload();
                } else {
                    redirectToHome();
                }
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectToHome = () => {
        navigate('/');
    };

    return <>
        <div className="connecting-loader">
            <h1 className="joinLoader">{backToMainRoomLoader ? 'Joining Main Room' : 'Joining Breakout Room'}</h1>
        </div>

    </>;
};
export default BreakoutConnecting;
