import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useSearchParams } from 'react-router-dom';
import { MainComponent } from './components/main';
import { socketConnect, socketDisconnect } from './utils/socket';
import { useDispatch, useStore } from 'react-redux';
import { recentUsersList } from './utils/functions';
import { useNavigate, useParams } from 'react-router-dom';
import {
  setBilling,
  setCurrentRoomName,
  setGuestDetail,
  setRoomDetails,
  setTrial,
  setVideoContainerSettings,
} from './reduxStore/confSlice';
import { setRouteDetail } from './reduxStore/mainSlice';
import { alertMsg } from './components/common/web/alert';
import { UnableToProcessMsg } from './utils/util';
import RoomErrorModal from './components/main/web/modals/roomErrorModal';
import {
  initdevices,
  startConference,
  disposeConference,
  isLocalParticipantKicked,
} from './libs';

export const conference = {
  _room: null,
  get membersCount() {
    return this._room?.getParticipants().length + 1;
  },
  getConnectionState() {
    return this._room && this._room?.getConnectionState();
  },
  getStats() {
    return this._room && this._room?.connectionQuality.getStats();
  },
  _isLocalParticipantKicked() {
    return isLocalParticipantKicked();
  },
  hangup() {
    disposeConference();
  },
};
window.APP = {
  conference,
};
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/:room"
          element={
            <MiddleWare>
              <MainComponent />
            </MiddleWare>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

function MiddleWare({ children }) {
  const store = useStore();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const room = params.room.toLowerCase();
  const state = store.getState();
  const { recentUsers } = state.persist;
  const [roomErrorShow, setRoomErrorShow] = useState(false);

  const [searchParams] = useSearchParams();
  const presenter_id = searchParams.get('p');
  const host_id = searchParams.get('h');
  const meeting_id = searchParams.get('id');
  const guestName = searchParams.get('name');

  useEffect(() => {
    dispatch(setRoomDetails(''));
    let token = [];
    if (recentUsers.length > 0) {
      recentUsers.forEach(val => {
        token.push(val.token);
      });
    }

    socketConnect(room, token, presenter_id, host_id, meeting_id).then(resp => {
      console.log('socketConnect', resp);
      if (resp?.status === 200) {
        dispatch(setRouteDetail(resp));
        console.log('resp', resp, resp?.trial);
        if (resp?.route === 'marketing') {
          socketDisconnect();
        } else {
          const guestDetail = { ...resp?.data.guest };
          if (guestName?.length > 0) {
            guestDetail.name = guestName;
          } else {
            guestDetail.name = 'X-NODE';
          }
          dispatch(setRoomDetails(resp?.data.room));
          dispatch(setGuestDetail(guestDetail));
          dispatch(setTrial(resp?.trial));
          dispatch(setBilling(resp?.billing));
          let roomDetail = { ...resp?.data.room };
          const {
            show_speaker_on_presentation,
            show_names_on_videos,
            hide_muted_attendees,
          } = roomDetail;

          dispatch(
            setVideoContainerSettings({
              show_speaker_on_presentation,
              show_names_on_videos,
              hide_muted_attendees,
            }),
          );
          dispatch(setCurrentRoomName(roomDetail?.room));
          const tokens = resp?.data.token;
          if (Array.isArray(tokens)) {
            tokens.forEach(t => {
              dispatch(recentUsersList(t));
            });
          }

          initdevices(dispatch, room, resp?.data.domain).then(() => {
            startConference(room);
          });
        }
      } else if (resp?.status === 422) {
        if (resp?.error === 'invalid_room') {
          setRoomErrorShow(true);
        } else {
          alertMsg({ msg: resp?.data[0].msg }, redirectToHome);
        }
      } else {
        alertMsg({ msg: UnableToProcessMsg }, redirectToHome);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const redirectToHome = () => {
    navigate('/');
  };
  return (
    <>
      {children} {roomErrorShow && <RoomErrorModal />}{' '}
    </>
  );
}
