import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import ConferenceHeader from './conferenceHeader';
import Connecting from './connecting';
import ConferenceInner from './conferenceInner';
import { LoaderEffect } from '../../common';
import {
  setMuteUnmutePermission,
  setRatingPopUp,
  setFailedCameraAlert,
  setWindowDimension,
} from '../../../reduxStore/confSlice';
import swal from 'sweetalert';
import { disposeConference } from '../../../libs';
import { alertMsg } from '../../common/web/alert';
import {
  END_MEETN_MSG,
  KICKED_OUT_MSG,
  MEETN_END_MSG,
  CAMERA_FAILED_MSG,
  WAITING_ROOM_DECLINED_MSG,
} from '../../../utils/util';
import BreakoutConnecting from './breakoutConnecting';

const Conference = () => {
  const {
    localUser,
    conferenceConnecting,
    roomDetail,
    conferenceFailed,
    conferenceLeft,
    failedCameraAlert,
    kickedOutRequest,
  } = useSelector(state => state.conf);

  const joinBreakoutRoom = useSelector(state => state.breakout.joinBreakoutRoom);
  const backToMainRoomLoader = useSelector(state => state.breakout.backToMainRoomLoader);
  const [loaderEffect, setloaderEffect] = useState(false);
  const [roomBackgroundSrc, setSource] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (roomDetail?.room_background) {
      // eslint-disable-next-line no-undef
      const img = new Image();
      img.src = roomDetail?.room_background;
      img.onload = () => setSource(roomDetail?.room_background);
    } else {
      setSource('');
    }
  }, [roomDetail?.room_background]);

  useEffect(() => {
    dispatch(setRatingPopUp(true));
  });
  useEffect(() => {
    if (failedCameraAlert) {
      const cb = () => {
        dispatch(setFailedCameraAlert(false));
      };
      alertMsg({ msg: CAMERA_FAILED_MSG }, cb);
    }
  }, [failedCameraAlert]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (conferenceFailed) {
      setloaderEffect(true);
      disposeConference().then(() => {
        alertMsg({ msg: MEETN_END_MSG }, redirectToHome);
      });
    }
  }, [conferenceFailed]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log('conferenceLeft', conferenceLeft);
    if (conferenceLeft) {
      setloaderEffect(true);
      disposeConference().then(msg => {
        if (msg !== 'error') {
          alertMsg({ msg: KICKED_OUT_MSG }, redirectToHome);
        }
      });
    }
  }, [conferenceLeft]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (roomDetail?.end_meetn && localUser.role === 'guest') {
      setloaderEffect(true);
      disposeConference().then(() => {
        alertMsg({ msg: END_MEETN_MSG }, redirectToHome);
      });
    }
  }, [localUser, roomDetail?.end_meetn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (kickedOutRequest) {
      setloaderEffect(true);
      disposeConference().then(() => {
        alertMsg({ msg: KICKED_OUT_MSG }, redirectToHome);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kickedOutRequest]);

  useEffect(() => {
    if (localUser?.waiting_status === 'declined') {
      disposeConference().then(() => {
        swal({
          title: WAITING_ROOM_DECLINED_MSG,
          closeOnClickOutside: false,
          button: {
            text: 'Exit',
          },
          dangerMode: true,
        }).then(Exit => {
          if (Exit) {
            redirectToHome();
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localUser]);

  useEffect(() => {
    const setWindowSize = () => {
      const { innerHeight, innerWidth } = window;

      dispatch(
        setWindowDimension({
          ...{
            clientWidth: innerWidth,
            clientHeight: innerHeight,
          },
        }),
      );
    };
    setWindowSize();
    window.addEventListener('resize', setWindowSize);
    return () => {
      window.removeEventListener('resize', setWindowSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToHome = () => {
    navigate('/');
  };

  const breakOutLoader = useMemo(() => {
    return joinBreakoutRoom || backToMainRoomLoader;
  }, [joinBreakoutRoom, backToMainRoomLoader]);

  return loaderEffect ? (
    <LoaderEffect />
  ) : (
    <>
      <ToastContainer
        hideProgressBar={true}
        draggable={false}
        autoClose={3000}
        closeOnClick={false}
      />
      <div className="conference-wrap">
        <ConferenceHeader />
        {conferenceConnecting ? (
          <Connecting />
        ) : (breakOutLoader) ? (
          <>
            <BreakoutConnecting joining={joinBreakoutRoom} />
          </>
        ) : (
          <ConferenceInner roomBackgroundSrc={roomBackgroundSrc} />
        )}
      </div>
    </>
  );
};
export default Conference;
